import React from "react";
import { Container } from "react-bootstrap";
import * as S from "src/styles/ui";

function Forbidden() {
  return (
    <Container fluid className="vh-100 vw-100 text-center p-5">
      <S.ErrorCode data-text="403">403</S.ErrorCode>
      <p className="lead mb-5">Forbidden</p>
      <p className="mb-0">Possible pilot deviation. Advise when ready to copy...</p>
    </Container>
  );
}

export default Forbidden;
