import React, { ReactNode } from "react";
import * as S from "src/styles/ui";

interface TableProps {
  children: ReactNode;
  maxHeight?: number;
}

const Table = React.forwardRef<HTMLDivElement, TableProps>(({ children, maxHeight }, ref) => {
  return (
    <S.ScrollContainer ref={ref} className="table-responsive" $maxHeight={maxHeight}>
      <table className="table table-head-fixed table-striped">{children}</table>
    </S.ScrollContainer>
  );
});

Table.displayName = "Table";

Table.defaultProps = {
  maxHeight: undefined,
};

export default Table;
