import React from "react";
import { VERSION } from "src/utils";

const YEAR = new Date().getFullYear();

function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        <span>Copyright © {YEAR} Nathan Rankin</span>
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b>
        <span className="ml-1">{VERSION}</span>
      </div>
    </footer>
  );
}

export default Footer;
