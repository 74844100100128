import { createTrainingWeatherAsync } from "@vatsim-vnas/js-libs/api/data";
import { TrainingWeather } from "@vatsim-vnas/js-libs/models/training";
import React, { useState } from "react";
import { ButtonGroup, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddIconButton, DeleteIconButton, EditIconButton, Table, TableNoRows } from "src/components/ui";
import { AddTrainingWeatherModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { addWeather, deleteTrainingWeather, trainingWeatherSelector, useAppDispatch, useAppSelector } from "src/redux";

function WeatherScenarios() {
  const weatherScenarios = useAppSelector(trainingWeatherSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<TrainingWeather>>({ show: false });

  const handleAdd = async (newWeather?: TrainingWeather) => {
    if (newWeather) {
      const createRes = await createTrainingWeatherAsync(newWeather);
      if (createRes.ok) {
        dispatch(addWeather(newWeather));
        toast.success("Successfully created weather scenario");
        navigate(`weather/${newWeather.id}`);
      } else {
        toast.error(`Failed to create weather scenario: ${createRes.statusText}`);
      }
    }
    setShowAddModal(false);
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteTrainingWeather(deleteModalSpec.item!.id));
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Weather Scenarios</Card.Title>
          <div className="card-tools">
            <AddIconButton text="Add Weather Scenario" onClick={() => setShowAddModal(true)} />
          </div>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {weatherScenarios.map((weather) => (
                <tr key={weather.id}>
                  <td>{weather.name}</td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => navigate(`weather/${weather.id}`)} />
                      <DeleteIconButton onClick={() => setDeleteModalSpec({ show: true, item: weather, itemName: weather.name })} />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows rows={weatherScenarios} text="No Weather Scenarios defined" />
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer />
      </Card>
      <AddTrainingWeatherModal show={showAddModal} onClose={handleAdd} />
      <DeleteModal show={deleteModalSpec.show} itemName={deleteModalSpec.itemName} onClose={handleDelete} />
    </>
  );
}

export default WeatherScenarios;
