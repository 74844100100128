import { faWater } from "@fortawesome/free-solid-svg-icons";
import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { InfoBox } from "src/components/ui";
import { artccSelector, nonNasFacilitiesSelector, useAppSelector } from "src/redux";

function ForeignFacilities() {
  const artcc = useAppSelector(artccSelector);
  const allNonNasFacilities = useAppSelector(nonNasFacilitiesSelector);
  const facilities = useMemo(() => artcc.getNonNasFacilities(allNonNasFacilities), []);

  if (!artcc.hasNeighboringNonNasFacilities()) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">Neighboring Foreign/Oceanic Facilities</h1>
      <section className="content">
        <Container fluid>
          <Row>
            {facilities.map((f) => (
              <Col lg={3} md={4} key={f.id}>
                <InfoBox
                  title={f.id}
                  subtitle={f.name}
                  navigateTo={f.id}
                  countryCode={!f.isOceanic ? f.countryCode : undefined}
                  icon={f.isOceanic ? faWater : undefined}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ForeignFacilities;
