import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { createTrainingScenarioAsync, getTrainingScenarioAsync } from "@vatsim-vnas/js-libs/api/data";
import { Scenario, ScenarioSummary } from "@vatsim-vnas/js-libs/models/training";
import React, { useMemo, useState } from "react";
import { ButtonGroup, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddIconButton, DeleteIconButton, EditIconButton, IconButton, Table, TableNoRows } from "src/components/ui";
import { AddTrainingScenarioModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { addScenario, deleteScenario, trainingScenarioSummariesSelector, useAppDispatch, useAppSelector } from "src/redux";
import { ulid } from "ulid";

function Scenarios() {
  const allScenarios = useAppSelector(trainingScenarioSummariesSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<ScenarioSummary>>({ show: false });

  const visibleScenarios = useMemo(() => allScenarios.filter((s) => s.name.toLowerCase().includes(search.toLowerCase())), [allScenarios, search]);

  const handleAdd = async (newScenario?: Scenario) => {
    if (newScenario) {
      const createRes = await createTrainingScenarioAsync(newScenario);
      if (createRes.ok) {
        toast.success("Successfully created scenario");
        dispatch(
          addScenario({
            id: newScenario.id,
            name: newScenario.name,
            primaryAirportId: newScenario.primaryAirportId,
            artccId: newScenario.artccId,
          }),
        );
        navigate(`scenarios/${newScenario.id}`);
      } else {
        toast.error(`Failed to create scenario: ${createRes.statusText}`);
      }
    }
    setShowAddModal(false);
  };

  const handleDuplicate = async (scenario: ScenarioSummary) => {
    const getRes = await getTrainingScenarioAsync(scenario.id);
    if (getRes.ok) {
      const newScenario = getRes.data!;
      newScenario.id = ulid();
      newScenario.name += ` (Copy)`;
      const createRes = await createTrainingScenarioAsync(newScenario);
      if (createRes.ok) {
        dispatch(
          addScenario({
            id: newScenario.id,
            name: newScenario.name,
            primaryAirportId: newScenario.primaryAirportId,
            artccId: newScenario.artccId,
          }),
        );
        navigate(`scenarios/${newScenario.id}`);
      } else {
        toast.error(`Failed to duplicate scenario: ${getRes.statusText}`);
      }
    } else {
      toast.error(`Failed to duplicate scenario: ${getRes.statusText}`);
    }
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteScenario(deleteModalSpec.item!.id));
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Training Scenarios</Card.Title>
          <div className="card-tools d-flex gap-3">
            <input className="form-control w-auto" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
            <AddIconButton text="Add Scenario" onClick={() => setShowAddModal(true)} />
          </div>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Primary Airport</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {visibleScenarios.map((scenario) => (
                <tr key={scenario.id}>
                  <td>{scenario.name}</td>
                  <td>{scenario.primaryAirportId}</td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => navigate(`scenarios/${scenario.id}`)} />
                      <IconButton type="secondary" onClick={() => handleDuplicate(scenario)} icon={faCopy} />
                      <DeleteIconButton onClick={() => setDeleteModalSpec({ show: true, item: scenario, itemName: scenario.name })} />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows rows={visibleScenarios} text="No Training Scenarios found" />
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer />
      </Card>
      <AddTrainingScenarioModal show={showAddModal} onClose={handleAdd} />
      <DeleteModal show={deleteModalSpec.show} itemName={deleteModalSpec.itemName} onClose={handleDelete} />
    </>
  );
}

export default Scenarios;
