import { TrainingAirportCustomApproach, TrainingAirport as TrainingAirportModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, EditIconButton, ErrorTableCell, Table } from "src/components/ui";
import { ModalSpec, TrainingAirportCustomApproachModal } from "src/components/ui/modal";

import { addToFormikArray, deleteFromFormikArray } from "src/utils/helpers";

interface CustomApproachConfigurationProps {
  formik: FormikProps<TrainingAirportModel>;
}

function CustomApproachConfiguration({ formik }: Readonly<CustomApproachConfigurationProps>) {
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  const handleAdd = () => {
    const index = addToFormikArray(formik, "customApproaches", new TrainingAirportCustomApproach());
    setModalSpec({ show: true, index });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Custom Approaches</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add Approach" onClick={handleAdd} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Form.Label>Custom Approaches</Form.Label>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Runway</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.customApproaches.map((approach, i) => (
                    <tr key={approach.id}>
                      <td>
                        <ErrorTableCell
                          formik={formik}
                          errorMessage="Invalid Custom Approach"
                          displayValue={approach.name}
                          name={`customApproaches[${i}]`}
                        />
                      </td>
                      <td>{approach.runwayId}</td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "customApproaches", approach)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingAirportCustomApproachModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default CustomApproachConfiguration;
