import { AutoDeleteMode, Scenario, autoDeleteModeToString } from "@vatsim-vnas/js-libs/models/training";
import { NetworkRating, networkRatingToString } from "@vatsim-vnas/js-libs/models/vnas/common";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useMemo, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Input, SelectInput } from "src/components/ui";
import { artccSelector, trainingAirportSummariesSelector, useAppSelector } from "src/redux";

interface ScenarioConfigurationProps {
  formik: FormikProps<Scenario>;
}

function ScenarioConfiguration({ formik }: Readonly<ScenarioConfigurationProps>) {
  const artcc = useAppSelector(artccSelector);
  const airports = useAppSelector(trainingAirportSummariesSelector);
  const facilities = useMemo(() => artcc.getAllFacilities().sort((a, b) => a.name.localeCompare(b.name)), []);
  const [selectedStudentFacilityId, setSelectedStudentFacilityId] = useState(
    facilities.find((f) => f.positions.some((p) => p.id === formik.values.studentPositionId))?.id,
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>General</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={3} className="mb-3">
            <Input formik={formik} name="name" placeholder="BOS Ground 1" label="Name" allowLowercase />
          </Col>
          <Col lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Artcc ID</Form.Label>
              <Form.Control disabled value={formik.values.artccId} />
            </Form.Group>
          </Col>
          <Col lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Student&apos;s Facility</Form.Label>
              <Form.Control
                className="form-control"
                as="select"
                value={selectedStudentFacilityId ?? "-1"}
                onChange={(e) => {
                  setSelectedStudentFacilityId(e.target.value !== "-1" ? e.target.value : undefined);
                  formik.setFieldValue("studentPositionId", undefined);
                }}
                data-default={!selectedStudentFacilityId}
              >
                <option value="-1">- Select Facility -</option>
                {facilities.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <SelectInput
              formik={formik}
              label="Student's Position"
              name="studentPositionId"
              options={
                selectedStudentFacilityId &&
                artcc.getFacility(selectedStudentFacilityId).positions.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} className="mb-3">
            <SelectInput
              formik={formik}
              name="primaryAirportId"
              label="Primary Airport"
              clearOption="None"
              options={airports.map((a) => (
                <option key={a.id} value={a.id}>
                  {a.id}
                </option>
              ))}
            />
          </Col>
          <Col lg={3} className="mb-3">
            <Input formik={formik} name="primaryApproach" label="Primary Approach" placeholder="I22L" />
          </Col>
          <Col lg={3} className="mb-3">
            <SelectInput
              formik={formik}
              name="autoDeleteMode"
              label="Auto Delete Mode"
              options={getEnumOptions(AutoDeleteMode, autoDeleteModeToString)}
            />
          </Col>
          <Col lg={3}>
            <SelectInput
              formik={formik}
              name="minimumRating"
              label="Minimum Network Rating"
              clearOption="None"
              options={
                <>
                  <option value={NetworkRating.Student3}>{networkRatingToString(NetworkRating.Student3)}</option>
                  <option value={NetworkRating.Controller1}>{networkRatingToString(NetworkRating.Controller1)}</option>
                  <option value={NetworkRating.Instructor1}>{networkRatingToString(NetworkRating.Instructor1)}</option>
                </>
              }
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default ScenarioConfiguration;
