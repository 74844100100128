import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScreenSize, calculateScreenSize } from "src/enums";
import { RootState } from "../store";

interface UiState {
  screenSize: ScreenSize;
  sidebarIsCollapsed: boolean;
  unsavedData: boolean;
}

const initialState: UiState = {
  screenSize: calculateScreenSize(window.innerWidth),
  sidebarIsCollapsed: false,
  unsavedData: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setScreenSize: (state, action: PayloadAction<ScreenSize>) => {
      state.screenSize = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarIsCollapsed = !state.sidebarIsCollapsed;
    },
    setUnsavedData: (state, action: PayloadAction<boolean>) => {
      state.unsavedData = action.payload;
    },
  },
});

export const { setScreenSize, toggleSidebar, setUnsavedData } = uiSlice.actions;
export const screenSizeSelector = (state: RootState) => state.ui.screenSize;
export const sidebarIsCollapsedSelector = (state: RootState) => state.ui.sidebarIsCollapsed;
export const unsavedDataSelector = (state: RootState) => state.ui.unsavedData;

export default uiSlice.reducer;
