import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import * as S from "src/styles/ui";
import { SortState } from "./SortableTableHeader";

interface SortableTableColumnHeaderProps {
  sortState: SortState;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

function SortableTableColumnHeader({ sortState, onClick, className, children }: Readonly<SortableTableColumnHeaderProps>) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <S.SortableTableColumnHeader
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
      className={className}
    >
      <div className="d-flex align-items-center">
        {children}
        <S.SortableTableColumnHeaderIcon
          icon={sortState === SortState.Descending ? faSortDown : faSortUp}
          $isHovering={isHovering}
          $sortState={sortState}
          className="ml-1"
        />
      </div>
    </S.SortableTableColumnHeader>
  );
}

SortableTableColumnHeader.defaultProps = {
  className: undefined,
};

export default SortableTableColumnHeader;
