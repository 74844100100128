import { beaconCodeToString } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, getIn } from "formik";
import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import * as S from "src/styles/ui";

interface BeaconCodeInputTableCellProps<F> {
  formik: FormikProps<F>;
  name: string;
  placeholder: string;
}

function BeaconCodeInputTableCell<F>({ name, formik, placeholder }: Readonly<BeaconCodeInputTableCellProps<F>>) {
  const [displayValue, setDisplayValue] = useState(getIn(formik.values, name) ? beaconCodeToString(getIn(formik.values, name)) : "");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!focused) {
      setDisplayValue(getIn(formik.values, name) ? beaconCodeToString(getIn(formik.values, name)) : "");
    }
  }, [focused, formik.values]);

  const handleBlur = (e: FocusEvent) => {
    setFocused(false);
    formik.handleBlur(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value);
    formik.handleChange(e);
  };

  return (
    <FormGroup>
      <S.FormControlTableCell
        autoComplete="off"
        isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        name={name}
        type="number"
        value={displayValue}
      />
      <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
    </FormGroup>
  );
}

export default BeaconCodeInputTableCell;
