import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector, userSelector } from "src/redux";

function UserAdminRoute() {
  const canManageUsers = useAppSelector(userSelector).canManageUsers();

  if (canManageUsers) {
    return <Outlet />;
  }

  return <Navigate to="/403" replace />;
}

export default UserAdminRoute;
