import { Facility, StarsHandoffId } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { DeleteIconButton, Input, Table, TableHeader, TableNoRows } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface HandoffsProps {
  formik: FormikProps<Facility>;
  handoffIds: StarsHandoffId[];
}

function Handoffs({ formik, handoffIds }: Readonly<HandoffsProps>) {
  const tableRef = useRef<HTMLDivElement>(undefined!);

  return (
    <>
      <Row>
        <TableHeader
          label="STARS Handoffs"
          addButtonLabel="Add Facility"
          onAdd={() => addToFormikArray(formik, "starsConfiguration.starsHandoffIds", new StarsHandoffId(), tableRef)}
        />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table ref={tableRef} maxHeight={500}>
            <thead>
              <tr>
                <th>Facility ID</th>
                <th>Handoff Number</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {handoffIds.map((handoffId, i) => (
                <tr key={handoffId.id}>
                  <td>
                    <Input tableCell formik={formik} name={`starsConfiguration.starsHandoffIds[${i}].facilityId`} placeholder="Y90" />
                  </td>
                  <td>
                    <Input tableCell name={`starsConfiguration.starsHandoffIds[${i}].handoffNumber`} formik={formik} number placeholder="1" />
                  </td>
                  <td>
                    <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "starsConfiguration.starsHandoffIds", handoffId)} />
                  </td>
                </tr>
              ))}
              <TableNoRows rows={handoffIds} text="No STARS Handoffs defined" />
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default Handoffs;
