import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { SortState } from "src/components/ui";
import styled from "styled-components";

export const PulsingLogo = styled.img`
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  border-radius: 50%;
  transform: scale(1);
  animation: pulse 2s infinite;
  height: 100px;
  width: 100px;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const ScaleIconContainer = styled.div`
  &:hover {
    .icon {
      transform: scale(1.1);
    }
  }
`;

export const BackgroundIcon = styled(FontAwesomeIcon)`
  color: rgba(255, 255, 255, 0.15);
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 70px;
  transition-duration: 0.3s;
  transition-timing-function: linear;
`;

export const SmallBackgroundIcon = styled(BackgroundIcon)`
  font-size: 50px;
`;

export const FlagImage = styled.img`
  width: 90%;
`;

export const ErrorCode = styled.div`
  color: #fff;
  font-size: 7rem;
  position: relative;
  line-height: 1;

  @-webkit-keyframes noise-anim {
    0% {
      clip: rect(81px, 9999px, 74px, 0);
    }
    5% {
      clip: rect(2px, 9999px, 29px, 0);
    }
    10% {
      clip: rect(1px, 9999px, 15px, 0);
    }
    15% {
      clip: rect(50px, 9999px, 8px, 0);
    }
    20% {
      clip: rect(99px, 9999px, 79px, 0);
    }
    25% {
      clip: rect(85px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(41px, 9999px, 53px, 0);
    }
    35% {
      clip: rect(22px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(73px, 9999px, 79px, 0);
    }
    45% {
      clip: rect(82px, 9999px, 100px, 0);
    }
    50% {
      clip: rect(47px, 9999px, 96px, 0);
    }
    55% {
      clip: rect(10px, 9999px, 44px, 0);
    }
    60% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    65% {
      clip: rect(29px, 9999px, 88px, 0);
    }
    70% {
      clip: rect(12px, 9999px, 44px, 0);
    }
    75% {
      clip: rect(25px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(38px, 9999px, 35px, 0);
    }
    85% {
      clip: rect(20px, 9999px, 96px, 0);
    }
    90% {
      clip: rect(58px, 9999px, 44px, 0);
    }
    95% {
      clip: rect(72px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(69px, 9999px, 72px, 0);
    }
  }

  @keyframes noise-anim {
    0% {
      clip: rect(81px, 9999px, 74px, 0);
    }
    5% {
      clip: rect(2px, 9999px, 29px, 0);
    }
    10% {
      clip: rect(1px, 9999px, 15px, 0);
    }
    15% {
      clip: rect(50px, 9999px, 8px, 0);
    }
    20% {
      clip: rect(99px, 9999px, 79px, 0);
    }
    25% {
      clip: rect(85px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(41px, 9999px, 53px, 0);
    }
    35% {
      clip: rect(22px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(73px, 9999px, 79px, 0);
    }
    45% {
      clip: rect(82px, 9999px, 100px, 0);
    }
    50% {
      clip: rect(47px, 9999px, 96px, 0);
    }
    55% {
      clip: rect(10px, 9999px, 44px, 0);
    }
    60% {
      clip: rect(7px, 9999px, 24px, 0);
    }
    65% {
      clip: rect(29px, 9999px, 88px, 0);
    }
    70% {
      clip: rect(12px, 9999px, 44px, 0);
    }
    75% {
      clip: rect(25px, 9999px, 57px, 0);
    }
    80% {
      clip: rect(38px, 9999px, 35px, 0);
    }
    85% {
      clip: rect(20px, 9999px, 96px, 0);
    }
    90% {
      clip: rect(58px, 9999px, 44px, 0);
    }
    95% {
      clip: rect(72px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(69px, 9999px, 72px, 0);
    }
  }

  &:after {
    content: attr(data-text);
    position: absolute;
    text-shadow: -1px 0 #e74a3b;
    color: #fff;
    background: #454d55;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    transform: translateX(-99%);
  }

  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(82px, 9999px, 93px, 0);
    }
    5% {
      clip: rect(13px, 9999px, 71px, 0);
    }
    10% {
      clip: rect(22px, 9999px, 90px, 0);
    }
    15% {
      clip: rect(17px, 9999px, 11px, 0);
    }
    20% {
      clip: rect(21px, 9999px, 91px, 0);
    }
    25% {
      clip: rect(37px, 9999px, 33px, 0);
    }
    30% {
      clip: rect(37px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(6px, 9999px, 49px, 0);
    }
    40% {
      clip: rect(86px, 9999px, 60px, 0);
    }
    45% {
      clip: rect(46px, 9999px, 83px, 0);
    }
    50% {
      clip: rect(16px, 9999px, 83px, 0);
    }
    55% {
      clip: rect(73px, 9999px, 36px, 0);
    }
    60% {
      clip: rect(58px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(20px, 9999px, 93px, 0);
    }
    70% {
      clip: rect(43px, 9999px, 67px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 75px, 0);
    }
    80% {
      clip: rect(66px, 9999px, 41px, 0);
    }
    85% {
      clip: rect(17px, 9999px, 47px, 0);
    }
    90% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    95% {
      clip: rect(8px, 9999px, 85px, 0);
    }
    100% {
      clip: rect(83px, 9999px, 50px, 0);
    }
  }

  @keyframes noise-anim-2 {
    0% {
      clip: rect(82px, 9999px, 93px, 0);
    }
    5% {
      clip: rect(13px, 9999px, 71px, 0);
    }
    10% {
      clip: rect(22px, 9999px, 90px, 0);
    }
    15% {
      clip: rect(17px, 9999px, 11px, 0);
    }
    20% {
      clip: rect(21px, 9999px, 91px, 0);
    }
    25% {
      clip: rect(37px, 9999px, 33px, 0);
    }
    30% {
      clip: rect(37px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(6px, 9999px, 49px, 0);
    }
    40% {
      clip: rect(86px, 9999px, 60px, 0);
    }
    45% {
      clip: rect(46px, 9999px, 83px, 0);
    }
    50% {
      clip: rect(16px, 9999px, 83px, 0);
    }
    55% {
      clip: rect(73px, 9999px, 36px, 0);
    }
    60% {
      clip: rect(58px, 9999px, 98px, 0);
    }
    65% {
      clip: rect(20px, 9999px, 93px, 0);
    }
    70% {
      clip: rect(43px, 9999px, 67px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 75px, 0);
    }
    80% {
      clip: rect(66px, 9999px, 41px, 0);
    }
    85% {
      clip: rect(17px, 9999px, 47px, 0);
    }
    90% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    95% {
      clip: rect(8px, 9999px, 85px, 0);
    }
    100% {
      clip: rect(83px, 9999px, 50px, 0);
    }
  }

  &:before {
    content: attr(data-text);
    position: absolute;
    text-shadow: 1px 0 #4e73df;
    color: #fff;
    background: #454d55;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    transform: translateX(1px);
  }
`;

export const ErrorText = styled.div`
  background-color: #343a40;
  border-radius: 10px;
  padding: 10px;
`;

export const Icon = styled.i`
  font-size: 70px !important;
`;

export const ScrollContainer = styled.div<{ $maxHeight?: number }>`
  max-height: ${(p) => p.$maxHeight && `${p.$maxHeight}px`};
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SaveFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 50;
`;

export const FormControlTableCell = styled(Form.Control)`
  background-color: #373e46 !important;
  padding: 0 12px;
  &:not(.is-invalid) {
    border: none;
  }
`;

export const BlankFormControl = styled(Form.Control)`
  cursor: inherit !important;
  background-color: inherit !important;
  &:not(.is-invalid) {
    border: none;
  }
`;

export const StarButton = styled(FontAwesomeIcon)<{ $starred: boolean }>`
  margin-left: 5px;
  transform: translateY(-10px);
  font-size: 20px;
  color: ${(p) => (p.$starred ? "#ffc107" : "#6c757d")};
  cursor: pointer;
`;

export const SortableTableColumnHeader = styled.th`
  cursor: pointer;
`;

export const SortableTableColumnHeaderIcon = styled(FontAwesomeIcon)<{ $isHovering: boolean; $sortState: SortState }>`
  visibility: ${(p) => (p.$sortState !== SortState.None || p.$isHovering ? "visible" : "hidden")};
  color: ${(p) => (p.$sortState === SortState.None && p.$isHovering ? "#6c757d" : "inherit")};
`;

export const DcbButton = styled.div<{ $isSelected: boolean }>`
  outline: ${(p) => (p.$isSelected ? "1px solid white" : "none")};
  background-color: inherit;
  cursor: pointer;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
`;

export const DropdownMenu = styled.div<{ $onLeft: boolean }>`
  left: ${(p) => (p.$onLeft ? "inherit" : "0")};
  right: ${(p) => (p.$onLeft ? "0" : "inherit")};
`;

export const IconButton = styled.button<{ $width?: number }>`
  width: ${(p) => (p.$width ? `${p.$width}px` : "")};
`;

export const Label = styled.span`
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 0.5rem;
  display: inline-block;
`;

export const Flag = styled.img`
  height: 40px;
`;

export const BcgInput = styled(Form.Control)`
  border: none;
  background-color: inherit;
  text-align: center;
`;

export const FilterInput = styled(Form.Control)<{ $secondLine?: boolean }>`
  border: none;
  text-align: center;
  margin-top: ${(p) => p.$secondLine && "-16px"};
`;

export const LabelTabel = styled.table`
  background-color: #343a40;
`;
