import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "src/styles/ui";

interface InfoBoxProps {
  countryCode?: string;
  icon?: IconProp;
  navigateTo: string;
  subtitle?: string;
  title: string;
}

function InfoBox({ countryCode, icon, navigateTo, subtitle, title }: Readonly<InfoBoxProps>) {
  const flagUrl = `https://flagcdn.com/h120/${countryCode?.toLowerCase()}.png`;
  let iconElement;

  if (countryCode) {
    iconElement = <S.FlagImage alt={countryCode} src={flagUrl} />;
  } else if (icon) {
    iconElement = <FontAwesomeIcon icon={icon} />;
  }

  return (
    <Link to={navigateTo}>
      <S.ScaleIconContainer className="info-box">
        <span className="info-box-icon elevation-1 bg-secondary">{iconElement}</span>
        <div className="info-box-content">
          <span className="info-box-number">{title}</span>
          <span className="info-box-text">{subtitle}</span>
        </div>
        <S.SmallBackgroundIcon icon={faChevronRight} className="icon" />
      </S.ScaleIconContainer>
    </Link>
  );
}

InfoBox.defaultProps = {
  subtitle: undefined,
  countryCode: undefined,
  icon: undefined,
};

export default InfoBox;
