import { AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CrossingLine } from ".";
import AltimeterStation from "./AltimeterStation";
import AltitudeConstraint from "./AltitudeConstraint";
import SpeedConstraint from "./SpeedConstraint";

interface DescentRestrictionProps {
  formik: FormikProps<AutoAtcRule>;
}

function DescentRestriction({ formik }: Readonly<DescentRestrictionProps>) {
  if (!formik.values.descentRestriction) {
    return <Card />;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Descent Restriction</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={6}>
            <CrossingLine formik={formik} name="descentRestriction.crossingLine" />
          </Col>
        </Row>
        <hr />
        <AltitudeConstraint formik={formik} baseName="descentRestriction" />
        <hr />
        <SpeedConstraint formik={formik} baseName="descentRestriction" allowConstraintType />
        <hr />
        <AltimeterStation formik={formik} baseName="descentRestriction" />
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default DescentRestriction;
