import { Scenario, ScenarioFlightStripConfiguration } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useMemo, useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, EditIconButton, SelectInput, Table, TableNoRows } from "src/components/ui";
import { ModalSpec, TrainingScenarioFlightStripConfigurationModal } from "src/components/ui/modal";
import { artccSelector, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface FlightStripConfigurationProps {
  formik: FormikProps<Scenario>;
}

function FlightStripConfiguration({ formik }: Readonly<FlightStripConfigurationProps>) {
  const artcc = useAppSelector(artccSelector);
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });
  const flightStripFacilitiesOptions = useMemo(
    () =>
      artcc
        .getAllFacilities()
        .filter((facility) => !!facility.flightStripsConfiguration)
        .map((facility) => (
          <option key={facility.id} value={facility.id}>
            {facility.name}
          </option>
        )),
    [],
  );

  const { flightStripConfigurations } = formik.values;

  const getRackOptions = (configuration: ScenarioFlightStripConfiguration) => {
    if (!configuration.bayId) {
      return undefined;
    }

    const { numberOfRacks } = artcc
      .getFacility(configuration.facilityId)
      .flightStripsConfiguration!.stripBays.find((b) => b.id === configuration.bayId)!;

    const rackNumbers = [...Array(numberOfRacks).keys()];

    return rackNumbers.map((rack) => (
      <option key={rack} value={rack}>
        {rack + 1}
      </option>
    ));
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Flight Strip Configuration</Card.Title>
        <div className="card-tools">
          <AddIconButton
            text="Add Flight Strip Configuration"
            onClick={() =>
              addToFormikArray(formik, "flightStripConfigurations", {
                ...new ScenarioFlightStripConfiguration(),
                facilityId: undefined!,
                bayId: undefined!,
                rack: undefined!,
              })
            }
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Facility</th>
                    <th>Bay</th>
                    <th>Rack</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {flightStripConfigurations.map((configuration, i) => (
                    <tr key={configuration.id}>
                      <td>
                        <SelectInput
                          formik={formik}
                          name={`flightStripConfigurations[${i}].facilityId`}
                          options={flightStripFacilitiesOptions}
                          valueProcessor={(value) => {
                            formik.setFieldValue(`flightStripConfigurations[${i}].bayId`, undefined);
                            formik.setFieldValue(`flightStripConfigurations[${i}].rack`, undefined);
                            return value;
                          }}
                        />
                      </td>
                      <td>
                        <SelectInput
                          formik={formik}
                          name={`flightStripConfigurations[${i}].bayId`}
                          options={
                            configuration.facilityId &&
                            artcc.getFacility(configuration.facilityId).flightStripsConfiguration!.stripBays.map((bay) => (
                              <option key={bay.id} value={bay.id}>
                                {bay.name}
                              </option>
                            ))
                          }
                          valueProcessor={(value) => {
                            formik.setFieldValue(`flightStripConfigurations[${i}].rack`, undefined);
                            return value;
                          }}
                        />
                      </td>
                      <td>
                        <SelectInput formik={formik} name={`flightStripConfigurations[${i}].rack`} options={getRackOptions(configuration)} />
                      </td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "flightStripConfigurations", configuration)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                  <TableNoRows rows={flightStripConfigurations} text="No Flight Strip Configurations defined" />
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingScenarioFlightStripConfigurationModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default FlightStripConfiguration;
