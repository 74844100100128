import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { removeFileExtension } from "@vatsim-vnas/js-libs/utils";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FeedbackLabel, Input, UploadFileButton } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import { FileUpload } from "src/utils";

interface AddVideoMapModalProps {
  show: boolean;
  onClose: (newVideoMap?: VideoMap, file?: File) => void;
}

function AddVideoMapModal({ show, onClose }: Readonly<AddVideoMapModalProps>) {
  const artcc = useAppSelector(artccSelector);
  const [file, setFile] = useState<File | undefined>();

  const formik = useFormik({
    initialValues: new VideoMap(),
    validationSchema: VideoMap.createSchema(artcc),
    onSubmit: (values) => onClose(values, file),
  });

  const handleUploadFile = (uploadedFile: FileUpload) => {
    if (uploadedFile instanceof File && uploadedFile.size > 0) {
      formik.setFieldValue("sourceFileName", uploadedFile.name);
      formik.setFieldError("file", undefined);
      if (!formik.values.name) {
        formik.setFieldValue("name", removeFileExtension(uploadedFile.name));
      }
      setFile(uploadedFile);
    } else {
      formik.setFieldValue("sourceFileName", "", false);
      formik.setFieldTouched("file", true, false);
      formik.setFieldError("file", "File is required");
    }
  };

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Video Map</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Row>
            <Col>
              <Input label="Name" name="name" formik={formik} placeholder="A90-003S (MVA)" allowLowercase />
            </Col>
          </Row>
          <Row className="mt-3 d-flex align-items-center">
            <Col>
              <Form.Group>
                <UploadFileButton accept=".geojson" fileName={formik.values.sourceFileName} label="Upload GeoJSON" onSelect={handleUploadFile} />
                <FeedbackLabel name="file" formik={formik} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid || !file}>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddVideoMapModal;
