import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Facility, StarsColorSet, StarsPositionConfiguration, Transceiver, starsColorSetToString } from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FrequencyInput, Input, SelectInput, Switch } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";

interface PositionModalProps {
  index: number | undefined;
  show: boolean;
  formik: FormikProps<Facility>;
  onClose: () => void;
}

function PositionModal({ index, show, formik, onClose }: Readonly<PositionModalProps>) {
  const artcc = useAppSelector(artccSelector);

  if (index === undefined) {
    return undefined;
  }

  const position = formik.values.positions[index];

  if (!position) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Position</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row className="">
          <Col className="d-flex align-items-end">
            <Input label="Name" name={`positions[${index}].name`} formik={formik} placeholder="Initial Departure" allowLowercase />
            <S.StarButton
              icon={faStar}
              $starred={position.starred}
              onClick={() => formik.setFieldValue(`positions[${index}].starred`, !position.starred)}
            />
          </Col>
          <Col>
            <Input label="Radio Name" name={`positions[${index}].radioName`} formik={formik} placeholder="Boston Departure" allowLowercase />
          </Col>
        </Row>
        <Row className="mt-3 ">
          <Col>
            <Input label="Callsign" name={`positions[${index}].callsign`} formik={formik} placeholder="BOS_DEP" />
          </Col>
          <Col>
            <FrequencyInput label="Frequency" name={`positions[${index}].frequency`} formik={formik} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Label>Transceivers</Form.Label>
            <Typeahead
              defaultSelected={artcc.transceivers.filter((t) => position.transceiverIds.includes(t.id))}
              flip
              labelKey={(option) => (option as Transceiver).name}
              multiple
              onChange={(o) =>
                formik.setFieldValue(
                  `positions[[${index}].transceiverIds`,
                  (o as Transceiver[]).map((t) => t.id),
                )
              }
              options={artcc.transceivers}
              paginate
              placeholder="Transceivers"
            />
          </Col>
        </Row>
        <hr />
        {position.eramConfiguration && (
          <Row>
            <Col size={6}>
              <Input label="Sector ID" name={`positions[${index}].eramConfiguration.sectorId`} formik={formik} placeholder="37" number />
            </Col>
          </Row>
        )}
        {formik.values.hasStarsFeed(artcc) && (
          <>
            {formik.values.isCabFacility() && (
              <Row>
                <Col size={6}>
                  <Switch
                    label="Enable STARS"
                    name={`positions[${index}].starsConfiguration`}
                    formik={formik}
                    checkedValue={() => ({
                      ...new StarsPositionConfiguration(),
                      areaId: undefined,
                    })}
                  />
                </Col>
              </Row>
            )}
            {position.starsConfiguration !== undefined && (
              <>
                <Row className="mt-3 ">
                  <Col>
                    <Input label="Sector ID" name={`positions[${index}].starsConfiguration.sectorId`} formik={formik} placeholder="D" />
                  </Col>
                  <Col>
                    <Input label="Subset" name={`positions[${index}].starsConfiguration.subset`} formik={formik} placeholder="1" number />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <SelectInput
                      label="Area"
                      name={`positions[${index}].starsConfiguration.areaId`}
                      formik={formik}
                      options={formik.values.getStarsFacility(artcc).starsConfiguration!.areas.map((g) => (
                        <option key={g.id} value={g.id}>
                          {g.name}
                        </option>
                      ))}
                    />
                  </Col>
                  <Col>
                    <SelectInput
                      formik={formik}
                      label="Color Set"
                      name={`positions[${index}].starsConfiguration.colorSet`}
                      options={getEnumOptions(StarsColorSet, starsColorSetToString)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PositionModal;
