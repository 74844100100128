import {
  AutoAtcRule,
  SpeedConstraint as SpeedConstraintModel,
  SpeedConstraintType,
  speedConstraintTypeToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, getIn } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Input, SelectInput, Switch } from "../ui";

interface SpeedConstraintProps {
  formik: FormikProps<AutoAtcRule>;
  baseName: string;
  allowConstraintType?: boolean;
}

const handleSetSpeed = (value: string | undefined, baseName: string, formik: FormikProps<AutoAtcRule>) => {
  if (value) {
    if (!formik.values.descentRestriction?.speedConstraint) {
      formik.setFieldValue(`${baseName}.speedConstraint`, new SpeedConstraintModel());
    }
    formik.setFieldValue(`${baseName}.speedConstraint.value`, parseInt(value, 10));
  } else {
    formik.setFieldValue(`${baseName}.speedConstraint`, undefined);
  }
  return value;
};

function SpeedConstraint({ formik, baseName, allowConstraintType }: Readonly<SpeedConstraintProps>) {
  return (
    <Row className="mt-3">
      <Col md className="mb-3">
        <Input
          formik={formik}
          name={`${baseName}.speedConstraint.value`}
          label="Speed"
          placeholder="250"
          number
          useUndefinedForEmpty
          valueProcessor={(v) => handleSetSpeed(v, baseName, formik)}
        />
      </Col>
      <Col md className="mb-3 d-flex align-items-center">
        <Switch
          label="Mach"
          disabled={!getIn(formik.values, `${baseName}.speedConstraint`)}
          name={`${baseName}.speedConstraint.isMach`}
          formik={formik}
        />
      </Col>
      {allowConstraintType && (
        <Col md className="mb-3">
          <SelectInput
            formik={formik}
            disabled={!getIn(formik.values, `${baseName}.speedConstraint`)}
            name={`${baseName}.speedConstraint.constraintType`}
            label="Constraint"
            options={getEnumOptions(SpeedConstraintType, speedConstraintTypeToString)}
          />
        </Col>
      )}
    </Row>
  );
}

export default SpeedConstraint;
