import { NAM_COORDINATION_ARTCCS } from "@vatsim-vnas/js-libs/constants";
import {
  AirportSingleChar,
  AsrSite,
  EramNeighboringCaatsConfiguration,
  EramNeighboringStarsConfiguration,
  Facility,
  FieldEFormat,
  GeoMap,
  beaconCodeBankCategoryToString,
  fieldEFormatToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import { beaconCodeToString, getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { instanceToInstance } from "class-transformer";
import { FormikProps } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import {
  CommaListInput,
  DeleteIconButton,
  EditIconButton,
  ErrorTableCell,
  Input,
  OrderButtonPair,
  SelectInput,
  Table,
  TableHeader,
  TableNoRows,
  TextareaInput,
} from "src/components/ui";
import { GeoMapModal, ModalSpec } from "src/components/ui/modal";
import { artccSelector, nonNasFacilitiesSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface EramConfigurationProps {
  formik: FormikProps<Facility>;
}

function EramConfiguration({ formik }: Readonly<EramConfigurationProps>) {
  const artcc = useAppSelector(artccSelector);
  const nonNasFacilities = useAppSelector(nonNasFacilitiesSelector);
  const accIds = useMemo(() => artcc.getNeighboringCaatsAccIds(nonNasFacilities), []);
  const [geoMapModalSpec, setGeoMapModalSpec] = useState<ModalSpec>({ show: false });
  const eramConfiguration = formik.values.eramConfiguration!;

  const asrTableRef = useRef<HTMLDivElement>(undefined!);
  const geoMapsTableRef = useRef<HTMLDivElement>(undefined!);
  const singleCharTableRef = useRef<HTMLDivElement>(undefined!);
  const starsTableRef = useRef<HTMLDivElement>(undefined!);

  const handleAddGeoMap = () => {
    const index = addToFormikArray(formik, "eramConfiguration.geoMaps", new GeoMap(), geoMapsTableRef);
    setGeoMapModalSpec({ show: true, index });
  };

  const isHandoffFacility = (facilityId: string) => {
    return artcc.getAllFacilities().some((f) => f.id === facilityId) || formik.values.neighboringFacilityIds.includes(facilityId);
  };

  const processStarsFacilityId = (facilityId: string | undefined, index: number) => {
    const isCurrentlyHandoff = formik.values.eramConfiguration!.neighboringStarsConfigurations[index].fieldEFormat !== FieldEFormat.FullStarsIdOnly;
    if (facilityId && isHandoffFacility(facilityId) && !isCurrentlyHandoff) {
      formik.setFieldValue(`eramConfiguration.neighboringStarsConfigurations[${index}].fieldEFormat`, FieldEFormat.OneLetterAndSubset);
    } else if (facilityId && !isHandoffFacility(facilityId) && isCurrentlyHandoff) {
      const newConfig = instanceToInstance(formik.values.eramConfiguration!.neighboringStarsConfigurations[index]);
      newConfig.singleCharacterStarsId = undefined;
      newConfig.twoCharacterStarsId = undefined;
      newConfig.fieldELetter = undefined;
      newConfig.fieldEFormat = FieldEFormat.FullStarsIdOnly;
      formik.setFieldValue(`eramConfiguration.neighboringStarsConfigurations[${index}]`, newConfig);
    }
    return facilityId;
  };

  useEffect(() => {
    formik.values.eramConfiguration!.neighboringStarsConfigurations.map((c) => c.facilityId).forEach(processStarsFacilityId);
  }, [formik.values.neighboringFacilityIds.length]);

  const getAccOptions = (selectedAcc?: string) => {
    return accIds
      .filter((i) => i === selectedAcc || !eramConfiguration.neighboringCaatsConfigurations.some((c) => c.accId === i))
      .map((i) => (
        <option value={i} key={i}>
          {i}
        </option>
      ));
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>ERAM Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md className="mb-3 mb-md-0">
            <Form.Group>
              <Form.Label>NAS ID</Form.Label>
              <Form.Control value={eramConfiguration.nasId} disabled />
            </Form.Group>
          </Col>
          <Col md className="mb-3 mb-md-0">
            <CommaListInput
              label="Additional Internal Airports"
              placeholder="CYCC, CYSL..."
              formik={formik}
              name="eramConfiguration.internalAirports"
            />
          </Col>
          <Col md>
            <CommaListInput label="Reference Fixes" placeholder="BOS, MLT..." formik={formik} name="eramConfiguration.referenceFixes" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={3}>
            <Input label="Conflict Alert Floor (MSL)" formik={formik} placeholder="3000" name="eramConfiguration.conflictAlertFloor" number />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg className="mb-3 mb-lg-0">
            <TextareaInput
              label="Emergency Checklist"
              placeholder="AIRCRAFT ALTITUDE&#10;FUEL REMAINING IN TIME..."
              formik={formik}
              name="eramConfiguration.emergencyChecklist"
            />
          </Col>
          <Col lg>
            <TextareaInput
              label="Position Relief Checklist"
              placeholder="SIA&#10;NAVAIDS..."
              formik={formik}
              name="eramConfiguration.positionReliefChecklist"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <S.Label>Beacon Code Allocation</S.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table maxHeight={500}>
              <thead>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Category</th>
                  <th>Priority</th>
                  <th>Subset</th>
                </tr>
              </thead>
              <tbody>
                {eramConfiguration.beaconCodeBanks.map((bank) => (
                  <tr key={bank.id}>
                    <td>{beaconCodeToString(bank.start)}</td>
                    <td>{beaconCodeToString(bank.end)}</td>
                    <td>{beaconCodeBankCategoryToString(bank.category)}</td>
                    <td>{bank.priority}</td>
                    <td>{bank.subset}</td>
                  </tr>
                ))}
                <TableNoRows rows={eramConfiguration.beaconCodeBanks} text="Contact a vNAS Administrator to add Beacon Code Banks" />
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row>
          <TableHeader
            label="ASR Sites"
            addButtonLabel="Add ASR Site"
            onAdd={() => addToFormikArray(formik, "eramConfiguration.asrSites", new AsrSite(), asrTableRef)}
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table maxHeight={500} ref={asrTableRef}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Range</th>
                  <th>Ceiling (100s ft MSL)</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {eramConfiguration.asrSites.map((asrSite, i) => (
                  <tr key={asrSite.id}>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.asrSites[${i}].asrId`} placeholder="ASR" />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.asrSites[${i}].location.lat`} placeholder="42.3355" number />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.asrSites[${i}].location.lon`} placeholder="-71.1685" number />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.asrSites[${i}].range`} placeholder="60" number />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.asrSites[${i}].ceiling`} placeholder="230" number />
                    </td>
                    <td>
                      <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "eramConfiguration.asrSites", asrSite)} />
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={eramConfiguration.asrSites} text="No ASR Sites defined" />
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row>
          <TableHeader label="GeoMaps" addButtonLabel="Add GeoMap" onAdd={handleAddGeoMap} />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table maxHeight={500}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {eramConfiguration.geoMaps.map((geoMap, i) => (
                  <tr key={geoMap.id}>
                    <td>
                      <ErrorTableCell
                        formik={formik}
                        displayValue={geoMap.name}
                        name={`eramConfiguration.geoMaps[${i}]`}
                        errorMessage="Invalid GeoMap"
                      />
                    </td>
                    <td>
                      <ButtonGroup>
                        <EditIconButton onClick={() => setGeoMapModalSpec({ show: true, index: i })} />
                        <OrderButtonPair formik={formik} name="eramConfiguration.geoMaps" index={i} />
                        <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "eramConfiguration.geoMaps", geoMap)} />
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={eramConfiguration.geoMaps} text="No GeoMaps defined" />
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <TableHeader
            label="Single Letter Airport Identifiers"
            addButtonLabel="Add Identifier"
            onAdd={() => addToFormikArray(formik, "eramConfiguration.airportSingleChars", new AirportSingleChar(), singleCharTableRef)}
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table maxHeight={500} ref={singleCharTableRef}>
              <thead>
                <tr>
                  <th>Airport ID</th>
                  <th>Single Letter</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {eramConfiguration.airportSingleChars.map((char, i) => (
                  <tr key={char.id}>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.airportSingleChars[${i}].airportId`} placeholder="BOS" />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.airportSingleChars[${i}].airportChar`} placeholder="B" />
                    </td>
                    <td>
                      <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "eramConfiguration.airportSingleChars", char)} />
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={eramConfiguration.airportSingleChars} text="No identifiers defined" />
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <TableHeader
            label="STARS Facilities"
            addButtonLabel="Add STARS Facility"
            onAdd={() =>
              addToFormikArray(formik, "eramConfiguration.neighboringStarsConfigurations", new EramNeighboringStarsConfiguration(), starsTableRef)
            }
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table maxHeight={500} ref={starsTableRef}>
              <thead>
                <tr>
                  <th>Facility</th>
                  <th>STARS ID</th>
                  <th>Single Character ID</th>
                  <th>Two Character ID</th>
                  <th>Field E Letter</th>
                  <th>Field E Format</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {eramConfiguration.neighboringStarsConfigurations.map((config, i) => (
                  <tr key={config.id}>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`eramConfiguration.neighboringStarsConfigurations[${i}].facilityId`}
                        placeholder="A90"
                        valueProcessor={(v) => processStarsFacilityId(v, i)}
                      />
                    </td>
                    <td>
                      <Input tableCell formik={formik} name={`eramConfiguration.neighboringStarsConfigurations[${i}].starsId`} placeholder="BBB" />
                    </td>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`eramConfiguration.neighboringStarsConfigurations[${i}].singleCharacterStarsId`}
                        placeholder="B"
                        useUndefinedForEmpty
                        disabled={!isHandoffFacility(config.facilityId)}
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`eramConfiguration.neighboringStarsConfigurations[${i}].twoCharacterStarsId`}
                        placeholder="BB"
                        useUndefinedForEmpty
                        disabled={!isHandoffFacility(config.facilityId)}
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`eramConfiguration.neighboringStarsConfigurations[${i}].fieldELetter`}
                        placeholder="B"
                        useUndefinedForEmpty
                        disabled={!isHandoffFacility(config.facilityId)}
                      />
                    </td>
                    <td>
                      <SelectInput
                        formik={formik}
                        name={`eramConfiguration.neighboringStarsConfigurations[${i}].fieldEFormat`}
                        options={getEnumOptions(
                          FieldEFormat,
                          fieldEFormatToString,
                          (f) => !isHandoffFacility(config.facilityId) || f !== FieldEFormat.FullStarsIdOnly,
                        )}
                        disabled={!isHandoffFacility(config.facilityId)}
                      />
                    </td>
                    <td>
                      <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "eramConfiguration.neighboringStarsConfigurations", config)} />
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={eramConfiguration.neighboringStarsConfigurations} text="No STARS Facilities defined" />
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        {!!accIds.length && (
          <>
            <Row className="mt-3">
              <TableHeader
                label="CAATS Facilities"
                addButtonLabel="Add CAATS Facility"
                addButtonDisabled={eramConfiguration.neighboringCaatsConfigurations.length === accIds.length}
                onAdd={() => addToFormikArray(formik, "eramConfiguration.neighboringCaatsConfigurations", new EramNeighboringCaatsConfiguration())}
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table maxHeight={500}>
                  <thead>
                    <tr>
                      <th>ACC</th>
                      <th>Handoff ID</th>
                      <th>Field E Letter</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eramConfiguration.neighboringCaatsConfigurations.map((config, i) => (
                      <tr key={config.id}>
                        <td>
                          <SelectInput
                            formik={formik}
                            name={`eramConfiguration.neighboringCaatsConfigurations[${i}].accId`}
                            options={getAccOptions(config.accId)}
                          />
                        </td>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`eramConfiguration.neighboringCaatsConfigurations[${i}].handoffLetter`}
                            placeholder="W"
                          />
                        </td>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`eramConfiguration.neighboringCaatsConfigurations[${i}].fieldELetter`}
                            placeholder="G"
                            useUndefinedForEmpty
                          />
                        </td>
                        <td>
                          <DeleteIconButton
                            onClick={() => deleteFromFormikArray(formik, "eramConfiguration.neighboringCaatsConfigurations", config)}
                          />
                        </td>
                      </tr>
                    ))}
                    <TableNoRows rows={eramConfiguration.neighboringCaatsConfigurations} text="No CAATS Facilities defined" />
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {!!eramConfiguration.atopHandoffLetter && (
          <Row className="mt-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>ATOP Handoff ID</Form.Label>
                <Form.Control value={eramConfiguration.atopHandoffLetter} disabled />
              </Form.Group>
            </Col>
          </Row>
        )}
        {NAM_COORDINATION_ARTCCS.includes(artcc.id) && (
          <Row className="mt-3">
            <Col>
              <CommaListInput label="Coordination Fixes" placeholder="MAM, NLD..." formik={formik} name="eramConfiguration.coordinationFixes" />
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer />
      <GeoMapModal
        formik={formik}
        show={geoMapModalSpec.show}
        index={geoMapModalSpec.index}
        onClose={() => setGeoMapModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default EramConfiguration;
