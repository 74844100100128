import React, { useImperativeHandle, useState } from "react";
import SortableTableColumnHeader from "./SortableTableColumnHeader";

export interface TableColumn {
  label: string;
  sortable: boolean;
  className?: string;
}

export interface SortSpec {
  column: string;
  ascending: boolean;
}

interface SortableTableHeaderProps {
  columns: TableColumn[];
  defaultSortAscending?: boolean;
  defaultSortColumn?: string;
  onSort: (sortSpec: SortSpec) => void;
}

export interface SortableTableHeaderHandle {
  resetSort: () => void;
}

export const enum SortState {
  None,
  Ascending,
  Descending,
}

const SortableTableHeader = React.forwardRef<SortableTableHeaderHandle, SortableTableHeaderProps>(
  ({ columns, defaultSortAscending, defaultSortColumn, onSort }, ref) => {
    const [sortState, setSortState] = useState(defaultSortAscending ? SortState.Ascending : SortState.Descending);
    const [sortColumn, setSortColumn] = useState(defaultSortColumn ?? columns.find((c) => c.sortable)!.label);

    const resetSort = () => {
      setSortState(SortState.Ascending);
      const column = columns.find((c) => c.sortable)?.label ?? "";
      setSortColumn(column);
      onSort({ column, ascending: true });
    };

    useImperativeHandle(ref, () => ({
      resetSort,
    }));

    const handleOnClick = (column: string) => {
      let newSortState;
      if (sortColumn === column) {
        if (sortState === SortState.Ascending) {
          newSortState = SortState.Descending;
        } else {
          newSortState = SortState.Ascending;
        }
      } else {
        newSortState = SortState.Ascending;
      }
      setSortColumn(column);
      setSortState(newSortState);
      onSort({ column, ascending: newSortState === SortState.Ascending });
    };

    return (
      <thead>
        <tr>
          {columns.map((c) =>
            c.sortable ? (
              <SortableTableColumnHeader
                key={c.label}
                className={c.className}
                sortState={sortColumn === c.label ? sortState : SortState.None}
                onClick={() => handleOnClick(c.label)}
              >
                {c.label}
              </SortableTableColumnHeader>
            ) : (
              <th key={c.label} className={c.className}>
                {c.label}
              </th>
            ),
          )}
        </tr>
      </thead>
    );
  },
);

SortableTableHeader.displayName = "SortableTableHeader";

SortableTableHeader.defaultProps = {
  defaultSortAscending: true,
  defaultSortColumn: undefined,
};

export default SortableTableHeader;
