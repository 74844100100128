import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { RpcRunway } from "src/components/facility";
import { Input } from "src/components/ui";

interface RpcModalProps {
  index: number | undefined;
  show: boolean;
  onClose: () => void;
  formik: FormikProps<Facility>;
}

function RpcModal({ index, show, onClose, formik }: Readonly<RpcModalProps>) {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl" dialogClassName="modal-xxl">
      <Modal.Header className="dark-mode">
        <Modal.Title>Runway Pair Configuration</Modal.Title>
      </Modal.Header>

      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <span className="mr-2">Airport ID:</span>
              <Input style={{ width: "125px" }} name={`starsConfiguration.rpcs[${index}].airportId`} formik={formik} placeholder="BOS" />
            </div>
          </Col>
          <Col className="d-flex align-items-end">
            <div className="d-flex align-items-center">
              <span className="mr-2">RPC ID:</span>
              <Input style={{ width: "75px" }} name={`starsConfiguration.rpcs[${index}].index`} formik={formik} placeholder="1" number />
            </div>
          </Col>
          <Col className="d-flex align-items-end">
            <div className="d-flex align-items-center">
              <span className="mr-2">Tie Position Symbol:</span>
              <Input style={{ width: "75px" }} name={`starsConfiguration.rpcs[${index}].positionSymbolTie`} formik={formik} placeholder="/" />
            </div>
          </Col>
          <Col className="d-flex align-items-end">
            <div className="d-flex align-items-center">
              <span className="mr-2">Stagger Position Symbol:</span>
              <Input style={{ width: "75px" }} name={`starsConfiguration.rpcs[${index}].positionSymbolStagger`} formik={formik} placeholder="O" />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg className="mb-3">
            <Row>
              <Col className="d-flex justify-content-center">
                <h3>Master Runway</h3>
              </Col>
            </Row>
            <RpcRunway isMaster index={index} formik={formik} />
          </Col>
          <Col lg className="left-divider">
            <Row>
              <Col className="d-flex justify-content-center">
                <h3>Slave Runway</h3>
              </Col>
            </Row>
            <RpcRunway index={index} formik={formik} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RpcModal;
