import { AdaptedRoute, AdaptedRouteType, TransitionFix, TransitionFixType } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DeleteIconButton, Input, SelectInput } from "src/components/ui";
import { deleteFromFormikArray } from "src/utils";
import Table from "./Table";
import TableHeader from "./TableHeader";

interface TransitionFixesTableProps {
  formik: FormikProps<AdaptedRoute>;
  readOnly: boolean;
}

function TransitionFixesTable({ formik, readOnly }: Readonly<TransitionFixesTableProps>) {
  const route = formik.values;
  const transitionFixTypeOptions =
    route.type === AdaptedRouteType.Departure
      ? [TransitionFixType.Explicit, TransitionFixType.Implicit, TransitionFixType.Append]
      : [TransitionFixType.Explicit, TransitionFixType.Implicit, TransitionFixType.Prepend];

  return (
    <>
      <Row>
        <TableHeader
          onAdd={() => {
            formik.setFieldValue("transitionFixes", [...formik.values.transitionFixes, new TransitionFix(TransitionFixType.Explicit)]);
          }}
          addButtonLabel="Add Fix"
          label="Transition Fixes"
        />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Fix</th>
                <th>Type</th>
                <th>Implicit Segment</th>
                {!readOnly && <th className="w-0">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {formik.values.transitionFixes.map((transitionFix, i) => (
                <tr key={transitionFix.id}>
                  <td>{readOnly ? transitionFix.fix : <Input tableCell formik={formik} name={`transitionFixes[${i}].fix`} placeholder="BUZRD" />}</td>
                  <td>
                    {readOnly ? (
                      transitionFix.type
                    ) : (
                      <SelectInput
                        name={`transitionFixes[${i}].type`}
                        formik={formik}
                        options={transitionFixTypeOptions.map((e) => (
                          <option key={e}>{e}</option>
                        ))}
                        valueProcessor={(value) => {
                          formik.setFieldValue(`transitionFixes[${i}].implicitSegment`, value === "Implicit" ? "" : undefined);
                          return value;
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {transitionFix.type === TransitionFixType.Implicit &&
                      (readOnly ? (
                        transitionFix.implicitSegment
                      ) : (
                        <Input tableCell name={`transitionFixes[${i}].implicitSegment`} formik={formik} placeholder="V1" />
                      ))}
                  </td>
                  {!readOnly && (
                    <td>
                      <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "transitionFixes", transitionFix)} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default TransitionFixesTable;
