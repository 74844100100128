import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { loggedInSelector, useAppSelector } from "src/redux";

function PublicRoute() {
  const loggedIn = useAppSelector(loggedInSelector);
  const loginNext = localStorage.getItem("login-next");

  if (loggedIn) {
    if (loginNext) {
      return <Navigate to={loginNext} replace />;
    }
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default PublicRoute;
