import { getTrainingScenarioAsync, loadArtccAsync, saveTrainingScenarioAsync } from "@vatsim-vnas/js-libs/api/data";
import { Scenario } from "@vatsim-vnas/js-libs/models/training";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AircraftConfiguration,
  ArrivalGeneratorConfiguration,
  AtcConfiguration,
  FlightStripConfiguration,
  InitializationTriggerConfiguration,
  ScenarioConfiguration,
} from "src/components/training/";
import { LoadingCard, SaveButtonBar } from "src/components/ui";

import { ARTCC_IDS } from "@vatsim-vnas/js-libs/constants";
import { Artcc } from "@vatsim-vnas/js-libs/models/facilities";
import { artccSelector, setUnsavedData, updateScenario, useAppDispatch, useAppSelector } from "src/redux";

function TrainingScenario() {
  const id = useParams().id!;
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();
  const [artccs, setArtccs] = useState<Artcc[]>([]);

  const getArtccData = async (artccId: string) => {
    const res = await loadArtccAsync(artccId);
    if (res.ok) {
      return res.data!;
    }
    toast.error(`Failed to load ${artccId}: ${res.statusText}`);
    return undefined;
  };

  const handleSubmit = async (values: Scenario, formik: FormikHelpers<Scenario>) => {
    const res = await saveTrainingScenarioAsync(values);
    if (res.ok) {
      toast.success("Successfully saved changes");
      formik.resetForm({ values });
      dispatch(
        updateScenario({
          id: values.id,
          name: values.name,
          primaryAirportId: values.primaryAirportId,
          artccId: values.artccId,
        }),
      );
    } else {
      toast.error(`Failed to save changes: ${res.statusText}`);
    }
  };

  const formik = useFormik({
    initialValues: undefined!,
    enableReinitialize: true,
    validationSchema: Scenario.schema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    (async () => {
      const neighboringArtccIds = artcc.facility.neighboringFacilityIds.filter((f) => ARTCC_IDS.includes(f));
      const promises = neighboringArtccIds.map(getArtccData);
      setArtccs([artcc, ...((await Promise.all(promises)).filter((a) => a) as Artcc[])]);
      const scenarioRes = await getTrainingScenarioAsync(id);
      if (scenarioRes.ok) {
        formik.resetForm({ values: scenarioRes.data! });
      } else {
        toast.error(`Failed to load scenario: ${scenarioRes.statusText}`);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (formik.values?.artccId && formik.values.artccId !== artcc.id) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">{formik.values?.name ?? "Scenario"}</h1>
      <section className="content">
        <Container fluid>
          {formik.values ? (
            <Form onSubmit={formik.handleSubmit}>
              <ScenarioConfiguration formik={formik} />
              <AircraftConfiguration formik={formik} artccs={artccs} />
              <AtcConfiguration formik={formik} artccs={artccs} />
              <InitializationTriggerConfiguration formik={formik} />
              <ArrivalGeneratorConfiguration formik={formik} artccs={artccs} />
              <FlightStripConfiguration formik={formik} />
              <SaveButtonBar formik={formik} backText="Back to Scenarios" />
            </Form>
          ) : (
            <LoadingCard text="Loading Scenario" title="General" />
          )}
        </Container>
      </section>
    </>
  );
}

export default TrainingScenario;
