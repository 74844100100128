import { AltimeterStation as AltimeterStationModel, AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps, getIn } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Input } from "../ui";

interface AltimeterStationProps {
  formik: FormikProps<AutoAtcRule>;
  baseName: string;
}

const handleSetAltimeterStation = (value: string | undefined, baseName: string, formik: FormikProps<AutoAtcRule>) => {
  if (value) {
    if (!formik.values.descentRestriction?.altimeterStation) {
      formik.setFieldValue(`${baseName}.altimeterStation`, new AltimeterStationModel());
    }
    formik.setFieldValue(`${baseName}.altimeterStation.stationId`, value);
  } else {
    formik.setFieldValue(`${baseName}.altimeterStation`, undefined);
  }
  return value;
};

function AltimeterStation({ formik, baseName }: Readonly<AltimeterStationProps>) {
  return (
    <Row className="mt-3">
      <Col md className="mb-3">
        <Input
          formik={formik}
          label="Altimeter Station ID"
          placeholder="KJFK"
          name={`${baseName}.altimeterStation.stationId`}
          useUndefinedForEmpty
          valueProcessor={(v) => handleSetAltimeterStation(v, baseName, formik)}
        />
      </Col>
      <Col md className="mb-3">
        <Input
          formik={formik}
          label="Altimeter Station Name"
          disabled={!getIn(formik.values, `${baseName}.altimeterStation`)}
          placeholder="Kennedy"
          name={`${baseName}.altimeterStation.stationName`}
          allowLowercase
          useUndefinedForEmpty
        />
      </Col>
    </Row>
  );
}

export default AltimeterStation;
