import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { distinct } from "@vatsim-vnas/js-libs/utils";
import React, { useMemo } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "src/components/ui";
import {
  artccSelector,
  loadArtcc,
  loadArtccsTrainingAirportSummaries,
  loadArtccsTrainingScenarios,
  loadArtccsTrainingWeather,
  setLoaded,
  useAppDispatch,
  useAppSelector,
  userSelector,
} from "src/redux";
import * as S from "src/styles/main";

function ArtccDropdown() {
  const artcc = useAppSelector(artccSelector);
  const { artccSummaries } = useAppSelector(userSelector);
  const editableArtccs = useMemo(() => [...artccSummaries].filter(distinct).sort((a, b) => a.name.localeCompare(b.name)), []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSelectArtcc = (id: string) => {
    localStorage.setItem("selected-artcc", id);
    navigate("/");
    dispatch(setLoaded(false));
    dispatch(loadArtcc(id));
    dispatch(loadArtccsTrainingAirportSummaries(id));
    dispatch(loadArtccsTrainingWeather(id));
    dispatch(loadArtccsTrainingScenarios(id));
  };

  return editableArtccs.length > 1 ? (
    <Dropdown
      buttonTemplate={<S.ArtccMenuText $button>{`${artcc.facility.name} (${artcc.id})`}</S.ArtccMenuText>}
      menuTemplate={
        <S.ArtccMenu>
          {editableArtccs.map((a) => (
            <Row key={a.id}>
              <S.ArtccMenuCaretWrapper>
                <FontAwesomeIcon icon={faCaretRight} className={a.id !== artcc.id ? "d-none" : ""} />
              </S.ArtccMenuCaretWrapper>
              <S.ArtccMenuItem onClick={() => handleSelectArtcc(a.id)}>
                {a.name} ({a.id})
              </S.ArtccMenuItem>
            </Row>
          ))}
        </S.ArtccMenu>
      }
    />
  ) : (
    <S.ArtccMenuText>
      {artcc.facility.name} ({artcc.id})
    </S.ArtccMenuText>
  );
}

export default ArtccDropdown;
