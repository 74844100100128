import { Facility, StarsConfiguration } from "@vatsim-vnas/js-libs/models/facilities";
import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { FormikProps } from "formik";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BaseSwitch, SortSpec, SortableTableHeader, Table, TableHeader, TableNoRows } from "src/components/ui";
import { DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { artccSelector, useAppSelector, videoMapsSelector } from "src/redux";

function sortFunction(sortSpec: SortSpec, a: VideoMap, b: VideoMap) {
  const mapA = sortSpec.ascending ? a : b;
  const mapB = sortSpec.ascending ? b : a;

  switch (sortSpec.column) {
    case "Short Name":
      return (mapA.shortName ?? "").localeCompare(mapB.shortName ?? "");
    case "Map ID":
      return mapA.starsId! - mapB.starsId!;
    case "Brightness Category":
      return mapA.starsBrightnessCategory.localeCompare(mapB.starsBrightnessCategory);
    default:
      return mapA.name.localeCompare(mapB.name);
  }
}

interface VideoMapsProps {
  formik: FormikProps<Facility>;
  starsConfiguration: StarsConfiguration;
}

function VideoMaps({ formik, starsConfiguration }: Readonly<VideoMapsProps>) {
  const artcc = useAppSelector(artccSelector);
  const videoMaps = useAppSelector(videoMapsSelector);

  const [sort, setSort] = useState<SortSpec>({ column: "Name", ascending: true });
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [onlyShowIncluded, setOnlyShowIncluded] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec>({ show: false });

  function handleVideoMapChecked(checked: boolean, videoMap: VideoMap) {
    let newVideoMapIds = [...starsConfiguration.videoMapIds];
    if (checked) {
      newVideoMapIds.push(videoMap.id);
    } else {
      const canDeleteSpec = starsConfiguration.canRemoveVideoMap(videoMap);
      if (!canDeleteSpec.canDelete) {
        setDeleteModalSpec({ show: true, canDeleteSpec, itemName: videoMap.name });
      } else {
        newVideoMapIds = newVideoMapIds.filter((i) => i !== videoMap.id);
      }
    }
    formik.setFieldValue("starsConfiguration.videoMapIds", newVideoMapIds);
  }

  const visibleVideoMaps = useMemo(
    () =>
      videoMaps
        .filter(
          (v) =>
            starsConfiguration.videoMapIds.includes(v.id) ||
            (v.starsId !== undefined &&
              !artcc.getVideoMapStarsIds(starsConfiguration.videoMapIds).includes(v.starsId) &&
              v.shortName !== undefined &&
              !artcc.getVideoMapShortNames(starsConfiguration.videoMapIds).includes(v.shortName)),
        )
        .filter((v) => v.name.toLowerCase().includes(search.toLowerCase()))
        .filter((v) => !tagsFilter.length || tagsFilter.every((t) => v.tags.includes(t)))
        .filter((v) => !onlyShowIncluded || starsConfiguration.videoMapIds.includes(v.id))
        .sort((a, b) => sortFunction(sort, a, b)),
    [search, tagsFilter, sort, onlyShowIncluded, starsConfiguration.videoMapIds],
  );

  return (
    <>
      <Row>
        <TableHeader label="Video Maps" searchValue={search} onSearch={setSearch} tagsValue={tagsFilter} onSelectTags={setTagsFilter}>
          <BaseSwitch label="Only Show Included" checked={onlyShowIncluded} onChange={(e) => setOnlyShowIncluded(e.target.checked)} />
        </TableHeader>
      </Row>
      <Row className="mt-2">
        <Col>
          <Table maxHeight={500}>
            <SortableTableHeader
              columns={[
                { label: "Name", sortable: true },
                { label: "Short Name", sortable: true },
                { label: "Map ID", sortable: true },
                { label: "Brightness Category", sortable: true },
                { label: "Include", sortable: false },
              ]}
              onSort={setSort}
            />
            <tbody>
              {visibleVideoMaps.map((videoMap) => (
                <tr key={videoMap.id}>
                  <td>{videoMap.name}</td>
                  <td>{videoMap.shortName}</td>
                  <td>{videoMap.starsId}</td>
                  <td>{videoMap.starsBrightnessCategory}</td>
                  <td>
                    <BaseSwitch
                      onChange={(e) => handleVideoMapChecked(e.target.checked, videoMap)}
                      checked={starsConfiguration.videoMapIds.includes(videoMap.id)}
                    />
                  </td>
                </tr>
              ))}
              <TableNoRows rows={visibleVideoMaps} text="No Video Maps found" />
            </tbody>
          </Table>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalSpec.show}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
        itemName={deleteModalSpec.itemName}
        action="Disable"
      />
    </>
  );
}

export default VideoMaps;
