import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EramPositionConfiguration,
  Facility as FacilityModel,
  FacilityType,
  Position,
  StarsColorSet,
  StarsPositionConfiguration,
  facilityTypeToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import { frequencyToString } from "@vatsim-vnas/js-libs/utils";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import {
  AsdexConfiguration,
  EramConfiguration,
  FlightStripsConfiguration,
  StarsConfiguration,
  TdlsConfiguration,
  TowerCabConfiguration,
} from "src/components/facility";
import {
  AddIconButton,
  BaseSwitch,
  CommaListInput,
  DeleteIconButton,
  EditIconButton,
  ErrorTableCell,
  Input,
  SaveButtonBar,
  Table,
  TableNoRows,
} from "src/components/ui";
import { DeleteModal, DeleteModalSpec, ModalSpec, PositionModal } from "src/components/ui/modal";
import { artccSelector, nonNasFacilitiesSelector, saveArtcc, saveFacility, setUnsavedData, useAppDispatch, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

function Facility() {
  const id = useParams().id!;

  const artcc = useAppSelector(artccSelector);
  const allNonNasFacilities = useAppSelector(nonNasFacilitiesSelector);
  const dispatch = useAppDispatch();

  const [positionModalSpec, setPositionModalSpec] = useState<ModalSpec>({ show: false });
  const [deletePositionModalSpec, setDeletePositionModalSpec] = useState<DeleteModalSpec<Position>>({ show: false });
  const facility = useMemo(() => artcc.getFacility(id), []);

  const handleSubmit = (values: FacilityModel, formik: FormikHelpers<FacilityModel>) => {
    dispatch(saveFacility(values));
    dispatch(saveArtcc("Successfully saved changes"));
    formik.resetForm({ values });
  };

  const formik = useFormik({
    initialValues: facility,
    enableReinitialize: true,
    validationSchema: facility.createSchema(artcc),
    onSubmit: handleSubmit,
  });

  const getStarsAreaStyle = (position: Position) => {
    if (!position.starsConfiguration) {
      return {};
    }
    switch (position.starsConfiguration.colorSet) {
      case StarsColorSet.Dod:
        return { color: "cyan" };
      case StarsColorSet.Tdw:
        return { color: "yellow" };
      default:
        return { color: "white" };
    }
  };

  const handleNonNasFacilityChecked = (facilityId: string, checked: boolean) => {
    let newNonNasFacilityIds = [...formik.values.nonNasFacilityIds];
    if (checked) {
      newNonNasFacilityIds.push(facilityId);
    } else {
      newNonNasFacilityIds = newNonNasFacilityIds.filter((i) => i !== facilityId);
    }
    formik.setFieldValue("nonNasFacilityIds", newNonNasFacilityIds);
  };

  const handleAddPosition = () => {
    const newPosition = new Position();
    if (facility.type === FacilityType.Artcc) {
      newPosition.eramConfiguration = new EramPositionConfiguration();
    }
    if (facility.type === FacilityType.Tracon) {
      newPosition.starsConfiguration = { ...new StarsPositionConfiguration(), areaId: undefined! };
    }
    const index = addToFormikArray(formik, "positions", newPosition);
    setPositionModalSpec({ show: true, index });
  };

  const handleDeletePosition = (position: Position) => {
    const canDeleteSpec = position.canDelete(artcc);
    if (canDeleteSpec.canDelete) {
      deleteFromFormikArray(formik, "positions", position);
    } else {
      setDeletePositionModalSpec({ show: true, item: position, itemName: position.name, canDeleteSpec });
    }
  };

  const showStarsArea = () => {
    return facility.isStarsFacility() || formik.values.positions.some((p) => p.starsConfiguration);
  };

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (!facility) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">
        {id} {facilityTypeToString(facility.type)}
      </h1>
      <section className="content">
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Card.Header>
                <Card.Title>General</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    <Input formik={formik} name="name" placeholder="Boston TRACON" label="Name" allowLowercase />
                  </Col>
                  <Col md className="mb-3">
                    <Form.Group>
                      <Form.Label>ID</Form.Label>
                      <Form.Control disabled value={facility.id} />
                    </Form.Group>
                  </Col>
                  <Col md className="mb-3">
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Form.Control disabled value={facilityTypeToString(facility.type)} />
                    </Form.Group>
                  </Col>
                  {facility.type !== FacilityType.Artcc && (
                    <Col md className="mb-3">
                      <Form.Group>
                        <Form.Label>Parent Facility</Form.Label>
                        <Form.Control disabled value={facility.getParentFacility(artcc).name} />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md className="mb-3">
                    <CommaListInput
                      placeholder="ZNY, ZDC, N90..."
                      formik={formik}
                      label={`Neighboring ${artcc.hasNeighboringNonNasFacilities() ? "Domestic" : ""} Facilities`}
                      name="neighboringFacilityIds"
                    />
                  </Col>
                  {artcc.hasNeighboringNonNasFacilities() && (
                    <Col md>
                      <Form.Group>
                        <Form.Label>Neighboring Foreign/Oceanic Facilities</Form.Label>
                        <Table maxHeight={200}>
                          <tbody>
                            {artcc.getNonNasFacilities(allNonNasFacilities).map((f) => (
                              <tr key={f.id}>
                                <td>
                                  <BaseSwitch
                                    disabled={facility.type === FacilityType.Artcc}
                                    label={`${f.name} (${f.id})`}
                                    checked={formik.values.nonNasFacilityIds.includes(f.id)}
                                    onChange={(e) => handleNonNasFacilityChecked(f.id, e.target.checked)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            {facility.type === FacilityType.Artcc && <EramConfiguration formik={formik} />}
            {facility.isStarsFacility() && <StarsConfiguration formik={formik} />}
            {facility.isCabFacility() && <TowerCabConfiguration formik={formik} />}
            {facility.isCabFacility() && facility.type !== FacilityType.AtctRapcon && <AsdexConfiguration formik={formik} />}
            {facility.isCabFacility() && facility.type !== FacilityType.AtctRapcon && <TdlsConfiguration formik={formik} />}
            {facility.type !== FacilityType.Artcc && <FlightStripsConfiguration formik={formik} />}
            <Card>
              <Card.Header>
                <Card.Title>Positions</Card.Title>
                <div className="card-tools">
                  <AddIconButton text="Add Position" onClick={handleAddPosition} />
                </div>
              </Card.Header>
              <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      {formik.values.positions.some((p) => p.starred) && <th className="w-0">&nbsp;</th>}
                      <th>Name</th>
                      <th>Frequency</th>
                      {showStarsArea() && <th>STARS Area</th>}
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.positions.map((position, i) => (
                      <tr key={position.id}>
                        {formik.values.positions.some((p) => p.starred) && (
                          <td>{position.starred && <FontAwesomeIcon icon={faStar} style={{ color: "#ffc107" }} />}</td>
                        )}
                        <td>
                          <ErrorTableCell formik={formik} errorMessage="Invalid Position" displayValue={position.name} name={`positions[${i}]`} />
                        </td>
                        <td>{frequencyToString(position.frequency)}</td>
                        {showStarsArea() && (
                          <th style={getStarsAreaStyle(position)}>
                            {position.starsConfiguration?.areaId && position.getStarsArea(artcc, formik.values).name}
                          </th>
                        )}
                        <td>
                          <ButtonGroup>
                            <EditIconButton onClick={() => setPositionModalSpec({ show: true, index: i })} />
                            <DeleteIconButton onClick={() => handleDeletePosition(position)} />
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                    <TableNoRows rows={formik.values.positions} text="No Positions defined" />
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer />
            </Card>
            <PositionModal
              formik={formik}
              index={positionModalSpec.index}
              show={positionModalSpec.show}
              onClose={() => {
                setPositionModalSpec((p) => ({ ...p, show: false }));
                const newPositions = [...formik.values.positions];
                newPositions.sort((a, b) => Position.positionSort(artcc, a, b));
                formik.setFieldValue("positions", newPositions);
                setTimeout(() => {
                  formik.validateForm();
                });
              }}
            />
            <SaveButtonBar formik={formik} backText="Back to Facilities" />
          </Form>
        </Container>
      </section>
      <DeleteModal
        show={deletePositionModalSpec.show}
        itemName={deletePositionModalSpec.itemName}
        canDeleteSpec={deletePositionModalSpec.canDeleteSpec}
        onClose={() => setDeletePositionModalSpec((p) => ({ ...p, show: false }))}
      />
    </>
  );
}

export default Facility;
