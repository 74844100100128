import { AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Input } from "../ui";
import AltimeterStation from "./AltimeterStation";
import CrossingLine from "./CrossingLine";

interface DescendViaProps {
  formik: FormikProps<AutoAtcRule>;
}

function DescendVia({ formik }: Readonly<DescendViaProps>) {
  if (!formik.values.descendVia) {
    return <Card />;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Descend Via</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md className="mb-3">
            <CrossingLine formik={formik} name="descendVia.crossingLine" />
          </Col>
          <Col md>
            <Input formik={formik} label="STAR Name" placeholder="JFUND2" name="descendVia.starName" />
          </Col>
        </Row>
        <hr />
        <AltimeterStation formik={formik} baseName="descendVia" />
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default DescendVia;
