import { createGlobalStyle } from "styled-components";
import "./imports.css";

const GlobalStyle = createGlobalStyle`
    .table td {
        vertical-align: middle !important;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .modal-xxl {
        max-width: 95%;
    }

    .left-divider {
        border-left: 1px solid rgba(0,0,0,.1);
    }

    .rbt-token {
        background: #6c757d !important;
        color: white !important;
    }

    input.rbt-input-main {
        color: white !important;
    }

    .w-0 {
        width: 0;
    }

    .gap-3 {
        gap: 1rem !important;
    }

    .btn-discord {
        color: #fff;
        background-color: #5865F2;
        border-color: #5865F2;

        &:hover {
            color: #fff;
            background-color: #4650c1;
            border-color: #4049b0;
        }
        
        &:active {
            color: #fff;
            background-color: #4049b0;
            border-color: #39419d;
        }
    }

    select {
        &[data-default="true"] {
            color: #939ba2  !important;
        }
        
        > option:not(:disabled) {
            color: white !important;
        }
    }
`;

export default GlobalStyle;
