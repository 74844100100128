import { NonNasPosition } from "@vatsim-vnas/js-libs/models/facilities";
import { frequencyToString } from "@vatsim-vnas/js-libs/utils";
import { useFormik } from "formik";
import React, { useMemo, useRef } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { AddIconButton, DeleteIconButton, FrequencyInput, Input, LoadingCard, SaveButtonBar, Table } from "src/components/ui";
import { artccSelector, nonNasFacilitiesSelector, saveNonNasFacility, useAppDispatch, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";
import * as yup from "yup";

function NonNasPositions() {
  const facilityId = useParams().facilityId!;
  const artcc = useAppSelector(artccSelector).facility;
  const nonNasFacilities = useAppSelector(nonNasFacilitiesSelector);
  const dispatch = useAppDispatch();
  const validationSchema = useRef<yup.AnyObjectSchema>();

  const facility = useMemo(() => {
    const nonNasFacility = nonNasFacilities.find((f) => f.id === facilityId);
    if (nonNasFacility) {
      validationSchema.current = nonNasFacility.createSchema(
        nonNasFacilities
          .filter((f) => f.id !== nonNasFacility.id && f.caatsAccId === nonNasFacility.caatsAccId && f.caatsAccId)
          .flatMap((f) => f.positions.map((p) => p.caatsSectorId!)),
      );
    }
    return nonNasFacility;
  }, [nonNasFacilities]);

  const formik = useFormik({
    initialValues: facility!,
    enableReinitialize: true,
    validationSchema: validationSchema.current,
    onSubmit: (values) => {
      dispatch(saveNonNasFacility(values));
      formik.resetForm({ values });
    },
  });

  if (!artcc.nonNasFacilityIds.includes(facilityId)) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header d-flex align-items-center">
        {facility?.name ?? "Facility"}
        {facility && !facility.isOceanic && (
          <S.Flag
            src={`https://flagcdn.com/h120/${facility.countryCode.toLowerCase()}.png`}
            className="ml-3"
            alt={facility.countryCode.toLowerCase()}
          />
        )}
      </h1>
      <section className="content">
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            {facility && formik.values ? (
              <Card>
                <Card.Header>
                  <Card.Title>Positions</Card.Title>
                  {(!facility.isOceanic || facility.owningArtccId === artcc.id) && (
                    <div className="card-tools">
                      <AddIconButton
                        text="Add Position"
                        onClick={() => addToFormikArray(formik, "positions", new NonNasPosition(facility.isOceanic))}
                      />
                    </div>
                  )}
                </Card.Header>
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Radio Name</th>
                        <th>Callsign Prefix</th>
                        <th>Callsign Suffix</th>
                        <th>Frequency</th>
                        {facility.caatsAccId && <th>Sector ID</th>}
                        {facility.isOceanic && <th>Sector ID</th>}
                        {(!facility.isOceanic || facility.owningArtccId === artcc.id) && <th className="w-0">Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.positions.map((position, i) =>
                        !facility.isOceanic || facility.owningArtccId === artcc.id ? (
                          <tr key={position.id}>
                            <td>
                              <Input tableCell formik={formik} name={`positions[${i}].name`} placeholder="Concordia" allowLowercase />
                            </td>
                            <td>
                              <Input tableCell formik={formik} name={`positions[${i}].radioName`} placeholder="Montréal Centre" allowLowercase />
                            </td>
                            <td>
                              <Input tableCell formik={formik} name={`positions[${i}].callsignPrefix`} placeholder="MTL" />
                            </td>
                            <td>
                              <Input tableCell formik={formik} name={`positions[${i}].callsignSuffix`} placeholder="CTR" />
                            </td>
                            <td>
                              <FrequencyInput tableCell formik={formik} name={`positions[${i}].frequency`} />
                            </td>
                            {facility.caatsAccId && (
                              <td>
                                <Input tableCell formik={formik} name={`positions[${i}].caatsSectorId`} placeholder="AY" useUndefinedForEmpty />
                              </td>
                            )}
                            {facility.isOceanic && (
                              <td>
                                <Input tableCell formik={formik} name={`positions[${i}].atopSectorId`} placeholder="01" useUndefinedForEmpty />
                              </td>
                            )}
                            <td>
                              <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "positions", position)} />
                            </td>
                          </tr>
                        ) : (
                          <tr key={position.id}>
                            <td>{position.name}</td>
                            <td>{position.radioName}</td>
                            <td>{position.callsignPrefix}</td>
                            <td>{position.callsignSuffix}</td>
                            <td>{frequencyToString(position.frequency)}</td>
                            {facility.isOceanic && <td>{position.atopSectorId}</td>}
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer />
              </Card>
            ) : (
              <LoadingCard text="Loading Facility" title="Facility" />
            )}
            <SaveButtonBar formik={formik} backText="Back to Foreign Facilities" />
          </Form>
        </Container>
      </section>
    </>
  );
}

export default NonNasPositions;
