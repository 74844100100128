import React, { StrictMode } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-right" toastStyle={{ color: "white", backgroundColor: "#202427" }} />
    </Provider>
  </StrictMode>,
);
