import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CommaListInput, Switch } from "src/components/ui";
import { Areas, BeaconCodeBanks, Handoffs, MapGroups, Rpcs, ScratchpadRules, VideoMaps } from "./stars-configuration";

interface StarsConfigurationProps {
  formik: FormikProps<Facility>;
}

function StarsConfiguration({ formik }: Readonly<StarsConfigurationProps>) {
  const starsConfiguration = formik.values.starsConfiguration!;

  return (
    <Card>
      <Card.Header>
        <Card.Title>STARS Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Switch name="starsConfiguration.allow4CharacterScratchpad" label="Allow four character scratchpad" formik={formik} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CommaListInput name="starsConfiguration.internalAirports" label="Internal Airports" placeholder="BOS, BED..." formik={formik} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CommaListInput name="starsConfiguration.rnavPatterns" label="RNAV Patterns" placeholder="BLZZR#, BRUWN#..." formik={formik} />
          </Col>
        </Row>
        <hr />
        <BeaconCodeBanks formik={formik} beaconCodeBanks={starsConfiguration.beaconCodeBanks} />
        <hr />
        <ScratchpadRules formik={formik} scratchpadRules={starsConfiguration.primaryScratchpadRules} isPrimary />
        <ScratchpadRules formik={formik} scratchpadRules={starsConfiguration.secondaryScratchpadRules} />
        <hr />
        <Rpcs formik={formik} rpcs={starsConfiguration.rpcs} />
        <hr />
        <Row>
          <Col md>
            <Areas formik={formik} areas={starsConfiguration.areas} />
          </Col>
          <Col className="left-divider">
            <Handoffs formik={formik} handoffIds={starsConfiguration.starsHandoffIds} />
          </Col>
        </Row>
        <hr />
        <VideoMaps formik={formik} starsConfiguration={starsConfiguration} />
        <hr />
        <MapGroups formik={formik} mapGroups={starsConfiguration.mapGroups} />
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default StarsConfiguration;
