import { TrainingWeather as TrainingWeatherModel, TrainingWeatherWindLayer } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, Input } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface WindLayerConfigurationProps {
  formik: FormikProps<TrainingWeatherModel>;
}

function WindLayerConfiguration({ formik }: Readonly<WindLayerConfigurationProps>) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Wind Layers</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add Layer" onClick={() => addToFormikArray(formik, "windLayers", new TrainingWeatherWindLayer())} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Altitude</th>
                    <th>Direction</th>
                    <th>Speed</th>
                    <th>Gusts</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.windLayers.map((layer, i) => (
                    <tr key={layer.id}>
                      <td>
                        <Input tableCell number formik={formik} name={`windLayers[${i}].altitude`} placeholder="10000" />
                      </td>
                      <td>
                        <Input tableCell number formik={formik} name={`windLayers[${i}].direction`} placeholder="090" />
                      </td>
                      <td>
                        <Input tableCell number formik={formik} name={`windLayers[${i}].speed`} placeholder="10" />
                      </td>
                      <td>
                        <Input tableCell number formik={formik} name={`windLayers[${i}].gusts`} placeholder="20" useUndefinedForEmpty />
                      </td>
                      <td>
                        <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "windLayers", layer)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default WindLayerConfiguration;
