import { Facility, LeaderDirection } from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CommaListInput, Input, SelectInput } from "src/components/ui";

interface RpcRunwayProps {
  formik: FormikProps<Facility>;
  index: number | undefined;
  isMaster?: boolean;
}

function RpcRunway({ formik, index, isMaster }: Readonly<RpcRunwayProps>) {
  const runwayString = `starsConfiguration.rpcs[${index}].${isMaster ? "masterRunway" : "slaveRunway"}`;

  return (
    <>
      <Row>
        <Col className="d-flex align-items-center">
          <span className="mr-2 text-nowrap">Runway ID:</span>
          <Input placeholder={isMaster ? "27" : "22L"} formik={formik} name={`${runwayString}.runwayId`} />
        </Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Heading Tolerance:</span>
          <Input placeholder="90" formik={formik} name={`${runwayString}.headingTolerance`} />
          <span className="ml-1">deg</span>
        </Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Label Orientation:</span>
          <SelectInput formik={formik} name={`${runwayString}.defaultLeaderDirection`} options={getEnumOptions(LeaderDirection)} />
        </Col>
      </Row>
      <Row className="mt-3 align-items-center">
        <Col size={2}>Target Ref. Pt.:</Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Latitude:</span>
          <Input placeholder="44.3355" formik={formik} name={`${runwayString}.targetReferencePoint.lat`} number />
        </Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Longitude:</span>
          <Input placeholder="-71.1685" formik={formik} name={`${runwayString}.targetReferencePoint.lon`} number />
        </Col>
      </Row>
      <Row className="mt-3 align-items-center">
        <Col size={2}>Image Ref. Pt.:</Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Latitude:</span>
          <Input placeholder="44.3355" formik={formik} name={`${runwayString}.imageReferencePoint.lat`} number />
        </Col>
        <Col className="d-flex align-items-center">
          <span className="mr-2">Longitude:</span>
          <Input placeholder="-71.1685" formik={formik} name={`${runwayString}.imageReferencePoint.lon`} number />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Target Ref. Line Dir.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="360.0" formik={formik} name={`${runwayString}.targetReferenceLineHeading`} number />
          <span className="ml-1">deg</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Image Ref. Line Dir.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="90.0" formik={formik} name={`${runwayString}.imageReferenceLineHeading`} number />
          <span className="ml-1">deg</span>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Target Ref. Line Len.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="30.0" formik={formik} name={`${runwayString}.targetReferenceLineLength`} number />
          <span className="ml-1">nm</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Image Ref. Line Len.:</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="30.0" formik={formik} name={`${runwayString}.imageReferenceLineLength`} number />
            <span className="ml-1">nm</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Tie Mode Offset:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="2.0" formik={formik} name={`${runwayString}.tieModeOffset`} number />
          <span className="ml-1">nm</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Descent Pt. Dist.:</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="10.0" formik={formik} name={`${runwayString}.descentPointDistance`} number />
            <span className="ml-1">nm</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Target Ref. Pt. Alt.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="0" formik={formik} name={`${runwayString}.targetReferencePointAltitude`} number />
          <span className="ml-1 text-nowrap">100s ft</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Descent Pt. Alt.:</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="30" formik={formik} name={`${runwayString}.descentPointAltitude`} number />
            <span className="ml-1 text-nowrap">100s ft</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Above Path Tolerance:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="8" formik={formik} name={`${runwayString}.abovePathTolerance`} number />
          <span className="ml-1 text-nowrap">100s ft</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Below Path Tolerance:</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="5" formik={formik} name={`${runwayString}.belowPathTolerance`} number />
            <span className="ml-1 text-nowrap">100s ft</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-center">
          <span className="mr-2">Scratchpad Patterns:</span>
          <CommaListInput formik={formik} placeholder="V17, L17..." name={`${runwayString}.scratchpadPatterns`} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <h5>Qualification Area</h5>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Near Side Dist.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="5.0" formik={formik} name={`${runwayString}.nearSideDistance`} number />
          <span className="ml-1 text-nowrap">nm</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Near Side Half Width:</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="3.0" formik={formik} name={`${runwayString}.nearSideHalfWidth`} number />
            <span className="ml-1 text-nowrap">nm</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <span>Region Len.:</span>
        </Col>
        <Col className="d-flex align-items-center">
          <Input placeholder="30.0" formik={formik} name={`${runwayString}.regionLength`} number />
          <span className="ml-1 text-nowrap">nm</span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <span>Far Side Half Width</span>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Input placeholder="6.0" formik={formik} name={`${runwayString}.farSideHalfWidth`} number />
            <span className="ml-1 text-nowrap">nm</span>
          </div>
        </Col>
      </Row>
    </>
  );
}

RpcRunway.defaultProps = {
  isMaster: false,
};

export default RpcRunway;
