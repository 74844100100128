import React from "react";
import { LoginSidebar } from "src/components/login";
import * as S from "src/styles/login";

function Login() {
  return (
    <>
      <S.LoginBackground />
      <LoginSidebar />
    </>
  );
}

export default Login;
