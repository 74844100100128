import { Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavItem = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ArtccMenu = styled(Container)`
  padding: 15px;
  width: 250px;
`;

export const ArtccMenuText = styled.b<{ $button?: boolean }>`
  color: #17a2b8;
  margin-left: ${(p) => !p.$button && "16px"};

  &:hover {
    color: ${(p) => p.$button && "#45b5c6"};
  }
`;

export const ArtccMenuItem = styled(Col)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ArtccMenuCaretWrapper = styled.div`
  width: 10px;
`;

export const UserHeader = styled.li`
  padding: 10px;
  text-align: center;
`;

export const Sidebar = styled.aside`
  margin-top: 57px;
`;

export const SidebarButton = styled(Link)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  text-align: left;
`;
