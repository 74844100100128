enum ScreenSize {
  Small,
  Medium,
  Large,
}

export const calculateScreenSize = (windowWidth: number) => {
  if (windowWidth >= 992) {
    return ScreenSize.Large;
  }
  if (windowWidth >= 768) {
    return ScreenSize.Medium;
  }
  return ScreenSize.Small;
};

export default ScreenSize;
