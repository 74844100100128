import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { calculateScreenSize } from "./enums";
import { useWindowSize } from "./hooks";
import { Login, Main } from "./modules";
import {
  Artcc,
  AutoAtc,
  AutoAtcRule,
  ErrorPage,
  Facilities,
  Facility,
  Forbidden,
  ForeignFacilities,
  NonNasPositions,
  PageNotFound,
  Training,
  TrainingAirport,
  TrainingScenario,
  TrainingWeather,
  Transceivers,
  UserManagement,
  VideoMap,
  VideoMaps,
} from "./pages";
import { getVnasConfiguration, screenSizeSelector, setScreenSize, useAppDispatch, useAppSelector } from "./redux";
import { FacilityDataRoute, PrivateRoute, PublicRoute, UserAdminRoute } from "./routes";
import { GlobalStyle } from "./styles";
import { addWindowClass } from "./utils";

function App() {
  const screenSize = useAppSelector(screenSizeSelector);
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  useEffect(() => {
    const size = calculateScreenSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setScreenSize(size));
    }
  }, [windowSize]);

  useEffect(() => {
    dispatch(getVnasConfiguration());
    addWindowClass("dark-mode");
    addWindowClass("layout-navbar-fixed");
  }, []);

  return (
    <>
      <GlobalStyle />
      <ErrorBoundary fallbackRender={ErrorPage}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Main />}>
                <Route path="/" element={<Artcc />} />
                <Route path="/" element={<FacilityDataRoute />}>
                  <Route path="/facilities" element={<Facilities />} />
                  <Route path="/facilities/:id" element={<Facility />} />
                  <Route path="/foreign-facilities" element={<ForeignFacilities />} />
                  <Route path="/foreign-facilities/:facilityId" element={<NonNasPositions />} />
                  <Route path="/video-maps" element={<VideoMaps />} />
                  <Route path="/transceivers" element={<Transceivers />} />
                  <Route path="/auto-atc" element={<AutoAtc />} />
                  <Route path="/auto-atc/:id" element={<AutoAtcRule />} />
                  <Route path="/video-maps/:id" element={<VideoMap />} />
                </Route>
                <Route path="/" element={<UserAdminRoute />}>
                  <Route path="/user-management" element={<UserManagement />} />
                </Route>
                <Route path="/training" element={<Training />} />
                <Route path="/training/airports/:id" element={<TrainingAirport />} />
                <Route path="/training/scenarios/:id" element={<TrainingScenario />} />
                <Route path="/training/weather/:id" element={<TrainingWeather />} />
              </Route>
            </Route>
            <Route path="/403" element={<Forbidden />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
