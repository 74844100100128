import { TrainingWeather } from "@vatsim-vnas/js-libs/models/training";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Input } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";

interface AddTrainingWeatherModalProps {
  show: boolean;
  onClose: (newWeather?: TrainingWeather) => void;
}

function AddTrainingWeatherModal({ show, onClose }: Readonly<AddTrainingWeatherModalProps>) {
  const artccId = useAppSelector(artccSelector).id;

  const formik = useFormik({ initialValues: new TrainingWeather(artccId), validationSchema: TrainingWeather.schema, onSubmit: onClose });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Weather Scenario</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Input label="Name" name="name" formik={formik} placeholder="North Ops Heavy Wind" allowLowercase />
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid}>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddTrainingWeatherModal;
