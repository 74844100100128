import { Facility, FacilityType, TowerListConfiguration } from "@vatsim-vnas/js-libs/models/facilities";
import { GeoPoint } from "@vatsim-vnas/js-libs/models/vnas/common";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { CommaListInput, DeleteIconButton, Input, Switch, Table, TableHeader } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface StarsAreaModalProps {
  index: number | undefined;
  show: boolean;
  formik: FormikProps<Facility>;
  onClose: () => void;
}

function StarsAreaModal({ index, show, formik, onClose }: Readonly<StarsAreaModalProps>) {
  if (index === undefined) {
    return undefined;
  }

  const area = formik.values.starsConfiguration!.areas[index];

  if (!area) {
    return undefined;
  }

  const visibilityCenterProcessor = (isLatitude: boolean) => (value: string | undefined) => {
    if (value !== undefined && !area.visibilityCenter) {
      formik.setFieldValue(`starsConfiguration.areas[${index}].visibilityCenter`, new GeoPoint());
      formik.setFieldValue(`starsConfiguration.areas[${index}].visibilityCenter.lon`, undefined);
      formik.setFieldValue(`starsConfiguration.areas[${index}].visibilityCenter.lat`, undefined);
    } else if (
      value === undefined &&
      formik.values.type !== FacilityType.Tracon &&
      area.visibilityCenter &&
      ((isLatitude && area.visibilityCenter.lon === undefined) || (!isLatitude && area.visibilityCenter.lat === undefined))
    ) {
      formik.setFieldValue(`starsConfiguration.areas[${index}].visibilityCenter`, undefined);
    }
    return value;
  };

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>STARS Area</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col lg={3} className="mb-3">
            <Input label="Name" name={`starsConfiguration.areas[${index}].name`} placeholder="Default" formik={formik} allowLowercase />
          </Col>
          <Col lg className="mb-3">
            <CommaListInput
              label="Underlying Airports"
              name={`starsConfiguration.areas[${index}].underlyingAirports`}
              formik={formik}
              placeholder="BOS, BED..."
              sort={false}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg className="mb-3">
            <Input
              number
              formik={formik}
              label="Visibility Center Latitude"
              placeholder="42.3355"
              useUndefinedForEmpty
              name={`starsConfiguration.areas[${index}].visibilityCenter.lat`}
              valueProcessor={visibilityCenterProcessor(true)}
            />
          </Col>
          <Col lg className="mb-3">
            <Input
              number
              formik={formik}
              label="Visibility Center Longitude"
              placeholder="-71.1685"
              name={`starsConfiguration.areas[${index}].visibilityCenter.lon`}
              useUndefinedForEmpty
              valueProcessor={visibilityCenterProcessor(false)}
            />
          </Col>
          <Col lg className="mb-3">
            <Input number formik={formik} label="Visibility Range" placeholder="50" name={`starsConfiguration.areas[${index}].surveillanceRange`} />
          </Col>
        </Row>
        <Row>
          <Col lg={3} className="mb-3">
            <CommaListInput
              label="SSA Airports"
              name={`starsConfiguration.areas[${index}].ssaAirports`}
              formik={formik}
              placeholder="BOS, BED..."
              sort={false}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <TableHeader
            label="Tower Lists"
            addButtonLabel="Add Tower List"
            onAdd={() => addToFormikArray(formik, `starsConfiguration.areas[${index}].towerListConfigurations`, new TowerListConfiguration())}
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Airport</th>
                  <th>Range</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {area.towerListConfigurations.map((towerList, i) => (
                  <tr key={towerList.id}>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`starsConfiguration.areas[${index}].towerListConfigurations[${i}].airportId`}
                        placeholder="BOS"
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        name={`starsConfiguration.areas[${index}].towerListConfigurations[${i}].range`}
                        formik={formik}
                        number
                        placeholder="30"
                      />
                    </td>
                    <td>
                      <DeleteIconButton
                        disabled={area.towerListConfigurations.length <= 1}
                        onClick={() => deleteFromFormikArray(formik, `starsConfiguration.areas[${index}].towerListConfigurations`, towerList)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].ldbBeaconCodesInhibited`}
              label="Inhibit display of beacon code in Limited Data Blocks"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].pdbGroundSpeedInhibited`}
              label="Inhibit display of ground speed in Partial Data Blocks"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].displayRequestedAltInFdb`}
              label="Display requested altitude in Full Data Blocks"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].useVfrPositionSymbol`}
              label='Use position symbol "V" for unowned VFR tracks'
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].showDestinationDepartures`}
              label="Show destination airport in scratchpad for departures"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].showDestinationPrimaryArrivals`}
              label="Show destination airport in scratchpad for primary airport arrivals"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Switch
              formik={formik}
              name={`starsConfiguration.areas[${index}].showDestinationSatelliteArrivals`}
              label="Show destination airport in scratchpad for satellite arrivals"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default StarsAreaModal;
