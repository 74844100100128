import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps, getIn } from "formik";
import React, { useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BaseSwitch, Input, Table, TableHeader } from "src/components/ui";
import { useAppSelector, videoMapsSelector } from "src/redux";
import * as S from "src/styles/ui";

interface GeoMapModalProps {
  formik: FormikProps<Facility>;
  index: number | undefined;
  onClose: () => void;
  show: boolean;
}

function GeoMapModal({ index, show, formik, onClose }: Readonly<GeoMapModalProps>) {
  const allVideoMaps = useAppSelector(videoMapsSelector);
  const videoMaps = useMemo(() => [...allVideoMaps].sort((a, b) => a.name.localeCompare(b.name)), []);
  const [videoMapsSearch, setVideoMapsSearch] = useState("");
  const [videoMapsTagsFilter, setVideoMapsTagsFilter] = useState<string[]>([]);

  const visibleVideoMaps = useMemo(
    () =>
      videoMaps
        .filter((v) => v.name.toLowerCase().includes(videoMapsSearch.toLowerCase()))
        .filter((m) => !videoMapsTagsFilter.length || videoMapsTagsFilter.every((t) => m.tags.includes(t))),
    [videoMaps, videoMapsSearch, videoMapsTagsFilter],
  );

  if (index === undefined) {
    return undefined;
  }

  const geoMap = formik.values.eramConfiguration!.geoMaps[index];

  if (!geoMap) {
    return undefined;
  }

  const generateBcgRows = (offset = 0) => {
    return Array.from({ length: 10 }, (_, i) => i + offset).map((i) => (
      <td key={i} className="p-0">
        <Form.Group>
          <S.BcgInput
            autoComplete="off"
            name={`eramConfiguration.geoMaps[${index}].bcgMenu[${i}]`}
            value={geoMap.bcgMenu[i]}
            onChange={(e) => formik.setFieldValue(`eramConfiguration.geoMaps[${index}].bcgMenu[${i}]`, e.target.value.toUpperCase())}
            onBlur={formik.handleBlur}
            isInvalid={
              getIn(formik.errors, `eramConfiguration.geoMaps[${index}].bcgMenu[${i}]`) &&
              getIn(formik.touched, `eramConfiguration.geoMaps[${index}].bcgMenu[${i}]`)
            }
          />
          <Form.Control.Feedback type="invalid">{getIn(formik.errors, `eramConfiguration.geoMaps[${index}].bcgMenu[${i}]`)}</Form.Control.Feedback>
        </Form.Group>
      </td>
    ));
  };

  const generateFilterRows = (offset = 0) => {
    return Array.from({ length: 10 }, (_, i) => i + offset).map((i) => (
      <td key={i} className="p-0">
        <Form.Group>
          <S.FilterInput
            autoComplete="off"
            name={`eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine1`}
            value={geoMap.filterMenu[i].labelLine1}
            onChange={(e) => formik.setFieldValue(`eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine1`, e.target.value.toUpperCase())}
            onBlur={formik.handleBlur}
            isInvalid={
              getIn(formik.errors, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine1`) &&
              getIn(formik.touched, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine1`)
            }
          />
          <Form.Control.Feedback type="invalid">
            {getIn(formik.errors, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine1`)}
          </Form.Control.Feedback>
          <S.FilterInput
            $secondLine
            autoComplete="off"
            name={`eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine2`}
            value={geoMap.filterMenu[i].labelLine2}
            onChange={(e) => formik.setFieldValue(`eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine2`, e.target.value.toUpperCase())}
            onBlur={formik.handleBlur}
            isInvalid={
              getIn(formik.errors, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine2`) &&
              getIn(formik.touched, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine2`)
            }
          />
          <Form.Control.Feedback type="invalid">
            {getIn(formik.errors, `eramConfiguration.geoMaps[${index}].filterMenu[${i}].labelLine2`)}
          </Form.Control.Feedback>
        </Form.Group>
      </td>
    ));
  };

  const handleVideoMapChecked = (checked: boolean, videoMapId: string) => {
    let newVideoMapIds = [...geoMap.videoMapIds];
    if (checked) {
      newVideoMapIds.push(videoMapId);
    } else {
      newVideoMapIds = newVideoMapIds.filter((i) => i !== videoMapId);
    }
    formik.setFieldValue(`eramConfiguration.geoMaps[${index}].videoMapIds`, newVideoMapIds);
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>GeoMap</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col lg={4}>
            <Input label="Name" name={`eramConfiguration.geoMaps[${index}].name`} formik={formik} placeholder="ZBWMAP" />
          </Col>
          <Col lg={4}>
            <Input label="Label Line 1" name={`eramConfiguration.geoMaps[${index}].labelLine1`} formik={formik} placeholder="ZBW" />
          </Col>
          <Col lg={4}>
            <Input label="Label Line 2" name={`eramConfiguration.geoMaps[${index}].labelLine2`} formik={formik} placeholder="MAP" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>BCG Menu</Form.Label>
              <S.LabelTabel className="table table-bordered">
                <tbody>
                  <tr>{generateBcgRows()}</tr>
                  <tr>{generateBcgRows(10)}</tr>
                </tbody>
              </S.LabelTabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Alternate BCG Menu</Form.Label>
              <S.LabelTabel className="table table-bordered">
                <tbody>
                  <tr>{generateBcgRows(20)}</tr>
                  <tr>{generateBcgRows(30)}</tr>
                </tbody>
              </S.LabelTabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Filter Menu</Form.Label>
              <S.LabelTabel className="table table-bordered">
                <tbody>
                  <tr>{generateFilterRows()}</tr>
                  <tr>{generateFilterRows(10)}</tr>
                </tbody>
              </S.LabelTabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Alternate Filter Menu</Form.Label>
              <S.LabelTabel className="table table-bordered">
                <tbody>
                  <tr>{generateFilterRows(20)}</tr>
                  <tr>{generateFilterRows(30)}</tr>
                </tbody>
              </S.LabelTabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Row>
              <TableHeader
                label="Video Maps"
                searchValue={videoMapsSearch}
                onSearch={setVideoMapsSearch}
                tagsValue={videoMapsTagsFilter}
                onSelectTags={setVideoMapsTagsFilter}
              />
            </Row>
            <Row className="mt-3">
              <Table maxHeight={500}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Include in GeoMap</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleVideoMaps.map((videoMap) => (
                    <tr key={videoMap.id}>
                      <td>{videoMap.name}</td>
                      <td>
                        <BaseSwitch
                          onChange={(e) => handleVideoMapChecked(e.target.checked, videoMap.id)}
                          checked={geoMap.videoMapIds.includes(videoMap.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={() => onClose()}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default GeoMapModal;
