import { faGlobe, faGraduationCap, faMap, faRobot, faTerminal, faTowerCell } from "@fortawesome/free-solid-svg-icons";
import { uploadAliasesAsync } from "@vatsim-vnas/js-libs/api/data";
import { ArtccRole } from "@vatsim-vnas/js-libs/models/data-api";
import { dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import React, { useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { DownloadIconButton, SmallBox, UploadFileButton, UploadFileButtonHandle } from "src/components/ui";
import {
  artccSelector,
  saveArtcc,
  trainingScenarioSummariesSelector,
  updateAliasDate,
  useAppDispatch,
  useAppSelector,
  userSelector,
  vnasConfigurationSelector,
} from "src/redux";
import * as S from "src/styles/ui";
import { FileUpload } from "src/utils";

function Artcc() {
  const artcc = useAppSelector(artccSelector);
  const vnasConfiguration = useAppSelector(vnasConfigurationSelector);
  const user = useAppSelector(userSelector);
  const trainingScenariosCount = useAppSelector(trainingScenarioSummariesSelector).length;
  const dispatch = useAppDispatch();

  const [uploadingAliases, setUploadingAliases] = useState(false);
  const aliasesInputRef = useRef<UploadFileButtonHandle>(undefined!);

  const handleUploadAliases = async (aliases: FileUpload) => {
    if (!aliases) {
      return;
    }
    setUploadingAliases(true);
    const res = await uploadAliasesAsync(artcc.id, aliases as File);
    if (res.ok) {
      dispatch(updateAliasDate());
      dispatch(saveArtcc("Successfully uploaded aliases"));
    } else {
      toast.error(`Failed to uploaded aliases: ${res.statusText}`);
    }
    setUploadingAliases(false);
    aliasesInputRef.current.clear();
  };

  return (
    <>
      <h1 className="content-header">
        {artcc.facility.name} ({artcc.facility.id})
      </h1>
      {user.isAdmin || user.hasRole(ArtccRole.Administrator, artcc.id) || user.hasRole(ArtccRole.FacilityEngineer, artcc.id) ? (
        <Container fluid>
          <Row>
            <Col lg={3} md={4}>
              <SmallBox count={artcc.getAllFacilities().length} title="Facilities" navigateTo="/facilities" icon="fa-solid fa-tower-control" />
            </Col>
            {artcc.hasNeighboringNonNasFacilities() && (
              <Col lg={3} md={4}>
                <SmallBox
                  count={artcc.facility.nonNasFacilityIds.length}
                  title="Foreign/Oceanic Facilities"
                  navigateTo="/foreign-facilities"
                  faIcon={faGlobe}
                />
              </Col>
            )}
            <Col lg={3} md={4}>
              <SmallBox count={artcc.videoMaps.length} title="Video Maps" navigateTo="/video-maps" faIcon={faMap} />
            </Col>
            <Col lg={3} md={4}>
              <SmallBox count={artcc.transceivers.length} title="Transceivers" navigateTo="/transceivers" faIcon={faTowerCell} />
            </Col>
            <Col lg={3} md={4}>
              <SmallBox count={artcc.autoAtcRules.length} title="Auto ATC Rules" navigateTo="/auto-atc" faIcon={faRobot} />
            </Col>
            <Col lg={3} md={4}>
              <SmallBox count={trainingScenariosCount} title="Training Scenarios" navigateTo="/training" faIcon={faGraduationCap} />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <Card>
                <S.ScaleIconContainer>
                  <Card.Header>
                    <Card.Title>Aliases</Card.Title>
                  </Card.Header>
                  <Card.Body className="position-relative">
                    <Row>
                      <Col sm={8}>
                        <UploadFileButton
                          label="Upload"
                          accept=".txt"
                          ref={aliasesInputRef}
                          processing={uploadingAliases}
                          fileName={`Last updated: ${artcc.aliasesLastUpdatedAt ? dateToZuluString(artcc.aliasesLastUpdatedAt) : "Never"}`}
                          onSelect={(f) => handleUploadAliases(f)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={8}>
                        <DownloadIconButton url={`${vnasConfiguration.dataApiBaseUrl}/artccs/${artcc.id}/aliases`} />
                      </Col>
                    </Row>
                    <S.BackgroundIcon icon={faTerminal} className="icon" />
                  </Card.Body>
                </S.ScaleIconContainer>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <SmallBox count={trainingScenariosCount} title="Training Scenarios" navigateTo="/training" faIcon={faGraduationCap} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Artcc;
