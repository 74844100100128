import { FormikProps, getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

interface FeedbackLabelProps<F> {
  formik: FormikProps<F>;
  name: string;
}

function FeedbackLabel<F>({ formik, name }: Readonly<FeedbackLabelProps<F>>) {
  return (
    <Form.Group>
      <Form.Control isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)} className="d-none" />
      <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default FeedbackLabel;
