import { configureStore } from "@reduxjs/toolkit";
import artccReducer from "./slices/artccSlice";
import authReducer from "./slices/authSlice";
import nonNasReducer from "./slices/nonNasSlice";
import trainingReducer from "./slices/trainingSlice";
import uiReducer from "./slices/uiSlice";

const store = configureStore({
  reducer: {
    ui: uiReducer,
    artcc: artccReducer,
    nonNas: nonNasReducer,
    auth: authReducer,
    training: trainingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
