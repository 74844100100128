import { faFileArrowUp, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadVideoMapAsync } from "@vatsim-vnas/js-libs/api/data";
import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { CanDeleteSpec, dateToZuluString, distinct } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Badge, ButtonGroup, Card, Container, Dropdown, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AddIconButton, DeleteIconButton, DownloadIconButton, EditIconButton, IconButton, Table, TableNoRows } from "src/components/ui";
import { AddVideoMapModal, BatchEditTagsModal, BatchUploadGeoJsonModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import {
  addVideoMap,
  artccSelector,
  deleteVideoMaps,
  saveArtcc,
  saveVideoMap,
  useAppDispatch,
  useAppSelector,
  vnasConfigurationSelector,
} from "src/redux";
import { pluralize } from "src/utils";

const ALL_FACILITIES_ID = "ALL";
const NO_FACILITY_ID = "NONE";

const videoMapsContains = (videoMaps: VideoMap[], videoMapId: string) => {
  return videoMaps.some((v) => v.id === videoMapId);
};

const getTagsBadges = (videoMap: VideoMap) => {
  return (
    <>
      {videoMap.tags.slice(0, 5).map((t) => (
        <Badge className="mr-2" bg="secondary" key={t}>
          {t}
        </Badge>
      ))}
      {videoMap.tags.length > 5 && <>...</>}
    </>
  );
};

interface VideoMapRowProps {
  checked: boolean;
  dataApiBaseUrl?: string;
  onChecked: (checked: boolean) => void;
  onDelete: () => void;
  videoMap: VideoMap;
}

function VideoMapRow({ checked, dataApiBaseUrl, onChecked, onDelete, videoMap }: VideoMapRowProps) {
  const artccId = useAppSelector(artccSelector).id;
  const navigate = useNavigate();

  const memoizedRow = useMemo(() => {
    return (
      <tr>
        <td>
          <Form.Check label="" checked={checked} onChange={(e) => onChecked(e.target.checked)} />
        </td>
        <td>{videoMap.name}</td>
        <td>{getTagsBadges(videoMap)}</td>
        <td>{videoMap.sourceFileName}</td>
        <td>{dateToZuluString(videoMap.lastUpdatedAt)}</td>
        <td>
          <ButtonGroup>
            {dataApiBaseUrl && <DownloadIconButton url={`${dataApiBaseUrl}/artccs/${artccId}/video-maps/${videoMap.id}`} text="" />}
            <EditIconButton onClick={() => navigate(videoMap.id)} />
            <DeleteIconButton onClick={onDelete} />
          </ButtonGroup>
        </td>
      </tr>
    );
  }, [checked]);

  return memoizedRow;
}

function VideoMaps() {
  const [searchParams, setSearchParams] = useSearchParams();
  const artcc = useAppSelector(artccSelector);
  const dataApiBaseUrl = useAppSelector(vnasConfigurationSelector)?.dataApiBaseUrl;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const search = searchParams.get("search") ?? "";
  const facilityFilter = searchParams.get("facility") ?? ALL_FACILITIES_ID;
  const tagsFilter: string[] = useMemo(() => JSON.parse(searchParams.get("tags") ?? "[]"), [searchParams.get("tags")]);
  const [checkedVideoMapIds, setCheckedVideoMapIds] = useState<string[]>([]);
  const [showAddVideoMapModal, setShowAddVideoMapModal] = useState(false);
  const [showBatchEditTagsModal, setShowBatchEditTagsModal] = useState(false);
  const [showBatchUploadGeoJsonModal, setShowBatchUploadGeoJsonModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<string[]>>({ show: false });
  const videoMapsCount = useRef(artcc.videoMaps.length);

  const filteredVideoMaps = useMemo(() => {
    const newVideoMaps = artcc.videoMaps
      .filter((m) => m.name.toLowerCase().includes(search.toLowerCase()))
      .filter((m) => !tagsFilter.length || tagsFilter.every((t) => m.tags.includes(t)))
      .filter(
        (m) =>
          facilityFilter === ALL_FACILITIES_ID ||
          artcc.getVideoMapFacilities(m.id).includes(facilityFilter) ||
          (!artcc.getVideoMapFacilities(m.id).length && facilityFilter === NO_FACILITY_ID),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    setCheckedVideoMapIds((prev) => prev.filter((i) => videoMapsContains(newVideoMaps, i)));
    return newVideoMaps;
  }, [artcc.videoMaps, search, tagsFilter, facilityFilter]);

  useEffect(() => {
    const deletedVideoMapsCount = videoMapsCount.current - artcc.videoMaps.length;
    if (deletedVideoMapsCount > 0) {
      dispatch(saveArtcc(`Successfully deleted ${deletedVideoMapsCount} ${pluralize("video map", deletedVideoMapsCount)}`));
      videoMapsCount.current = artcc.videoMaps.length;
    }
  }, [artcc.videoMaps]);

  const handleHeaderChecked = (checked: boolean) => {
    if (checked) {
      setCheckedVideoMapIds(filteredVideoMaps.map((v) => v.id));
    } else {
      setCheckedVideoMapIds([]);
    }
  };

  const handleVideoMapChecked = (videoMap: VideoMap, checked: boolean) => {
    if (checked) {
      setCheckedVideoMapIds((prev) => [...prev, videoMap.id]);
    } else {
      setCheckedVideoMapIds((prev) => prev.filter((i) => i !== videoMap.id));
    }
  };

  const handleAdd = (newVideoMap: VideoMap | undefined, file: File | undefined) => {
    if (newVideoMap !== undefined && file !== undefined) {
      uploadVideoMapAsync(artcc.id, newVideoMap.id, file).then((r) => {
        if (r.ok) {
          dispatch(addVideoMap(newVideoMap));
          dispatch(saveArtcc("Successfully added video map"));
          navigate(`${newVideoMap.id}`);
        } else {
          toast.error(`Failed to upload video map: ${r.statusText}`);
        }
      });
    }
    setShowAddVideoMapModal(false);
  };

  const handleBatchEdit = (addTags: string[], removeTags: string[]) => {
    setShowBatchEditTagsModal(false);
    if (addTags.length || removeTags.length) {
      checkedVideoMapIds.forEach((i) => {
        const videoMap = artcc.getVideoMap(i);
        videoMap.tags = videoMap.tags
          .concat(addTags)
          .filter((t) => !removeTags.includes(t))
          .filter(distinct);
        dispatch(saveVideoMap(videoMap));
      });
      dispatch(saveArtcc("Successfully edited tags"));
    }
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteVideoMaps(deleteModalSpec.item!));
      setCheckedVideoMapIds([]);
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <h1 className="content-header">Video Maps</h1>
      <section className="content">
        <Container fluid>
          <Card>
            <Card.Header>
              <div className="card-tools d-flex flex-wrap gap-3">
                <Typeahead
                  id="video-map-tags"
                  className="w-auto"
                  options={artcc.getVideoMapTags()}
                  defaultSelected={tagsFilter}
                  flip
                  placeholder="Filter tags..."
                  paginate
                  multiple
                  onChange={(t) =>
                    setSearchParams(
                      (p) => {
                        p.set("tags", JSON.stringify(t));
                        return p;
                      },
                      { replace: true },
                    )
                  }
                />
                <input
                  className="form-control w-auto"
                  value={search}
                  onChange={(e) =>
                    setSearchParams(
                      (p) => {
                        p.set("search", e.target.value);
                        return p;
                      },
                      { replace: true },
                    )
                  }
                  placeholder="Search..."
                />
                <Form.Group className="w-auto">
                  <Form.Control
                    as="select"
                    value={facilityFilter}
                    onChange={(e) =>
                      setSearchParams(
                        (p) => {
                          p.set("facility", e.target.value);
                          return p;
                        },
                        { replace: true },
                      )
                    }
                  >
                    <option value={ALL_FACILITIES_ID}>All facilities</option>
                    {artcc.getAllFacilities().map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.id}
                      </option>
                    ))}
                    <option value={NO_FACILITY_ID}>None (unused)</option>
                  </Form.Control>
                </Form.Group>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" disabled={!checkedVideoMapIds.length}>
                    <FontAwesomeIcon icon={faList} className="mr-2" />
                    Batch Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowBatchEditTagsModal(true)}>Edit Tags</Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setDeleteModalSpec({
                          show: true,
                          itemName: `${checkedVideoMapIds.length} video maps`,
                          item: checkedVideoMapIds,
                          canDeleteSpec: new CanDeleteSpec(
                            checkedVideoMapIds
                              .map((i) => artcc.getVideoMap(i).canDelete(artcc))
                              .filter((s) => !s.canDelete)
                              .flatMap((s) => s.errors),
                          ),
                        })
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <IconButton icon={faFileArrowUp} text="Batch Upload GeoJSON" type="success" onClick={() => setShowBatchUploadGeoJsonModal(true)} />
                <AddIconButton text="Add Video Map" onClick={() => setShowAddVideoMapModal(true)} />
              </div>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th className="w-0">
                      <Form.Check
                        label=""
                        checked={!!filteredVideoMaps.length && checkedVideoMapIds.length === filteredVideoMaps.length}
                        onChange={(e) => handleHeaderChecked(e.target.checked)}
                      />
                    </th>
                    <th>Name</th>
                    <th>Tags</th>
                    <th>File Name</th>
                    <th>Last Updated</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredVideoMaps.map((videoMap) => (
                    <VideoMapRow
                      checked={checkedVideoMapIds.includes(videoMap.id)}
                      dataApiBaseUrl={dataApiBaseUrl}
                      key={videoMap.id}
                      onChecked={(c) => handleVideoMapChecked(videoMap, c)}
                      onDelete={() => {
                        setDeleteModalSpec({
                          show: true,
                          item: [videoMap.id],
                          itemName: videoMap.name,
                          canDeleteSpec: videoMap.canDelete(artcc),
                        });
                      }}
                      videoMap={videoMap}
                    />
                  ))}
                  <TableNoRows rows={filteredVideoMaps} text="No Video Maps found" />
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer />
          </Card>
        </Container>
      </section>
      <AddVideoMapModal show={showAddVideoMapModal} onClose={handleAdd} />
      <BatchUploadGeoJsonModal show={showBatchUploadGeoJsonModal} onClose={() => setShowBatchUploadGeoJsonModal(false)} />
      <BatchEditTagsModal show={showBatchEditTagsModal} videoMapCount={checkedVideoMapIds.length} onClose={handleBatchEdit} />
      <DeleteModal
        show={deleteModalSpec.show}
        onClose={handleDelete}
        itemName={deleteModalSpec.itemName}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
      />
    </>
  );
}

export default VideoMaps;
