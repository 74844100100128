import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProcessButton } from "src/components/ui";
import { checkLogin, login, useAppDispatch } from "src/redux";
import * as S from "src/styles/login";
import { DOMAIN, REFRESH_LOGIN_TIME, VATSIM_CLIENT_ID, VERSION } from "src/utils";

const REDIRECT_URL = encodeURIComponent(`${DOMAIN}/login`);
const VATSIM_LOGIN_URL = `https://auth.vatsim.net/oauth/authorize?client_id=${VATSIM_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code`;

function LoginSidebar() {
  const [searchParams, setSearchParams] = useSearchParams();
  let code: string | undefined = searchParams.get("code") ?? undefined;
  const dispatch = useAppDispatch();
  const [isLoggingIn, setIsLoggingIn] = useState(!!code);

  useEffect(() => {
    if (code) {
      dispatch(login({ code, redirectUrl: REDIRECT_URL }));
      setInterval(() => {
        dispatch(checkLogin());
      }, REFRESH_LOGIN_TIME);
    }

    return () => {
      setSearchParams((p) => {
        p.delete("code");
        return p;
      });
      code = undefined;
    };
  }, []);

  return (
    <S.LoginSidebar>
      <S.LoginPanel>
        <img src="/img/logo.png" alt="CRC Logo" height="100" width="100" />
        <S.Title>vNAS Data Admin</S.Title>
        <ProcessButton
          processing={isLoggingIn}
          onClick={() => {
            setIsLoggingIn(true);
            window.location.href = VATSIM_LOGIN_URL;
          }}
          disabled={isLoggingIn}
          text="Login with VATSIM"
        />
      </S.LoginPanel>
      <S.Footer>
        <b>Version</b>
        <span className="ml-1">{VERSION}</span>
      </S.Footer>
    </S.LoginSidebar>
  );
}

export default LoginSidebar;
