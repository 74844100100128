import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBookOpen, faGlobe, faGraduationCap, faHome, faMap, faRobot, faTowerCell } from "@fortawesome/free-solid-svg-icons";
import { ArtccRole, UserInfo } from "@vatsim-vnas/js-libs/models/data-api";
import { Artcc } from "@vatsim-vnas/js-libs/models/facilities";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SidebarItem, SidebarItemModel } from "src/components/main";
import { artccSelector, useAppSelector, userSelector } from "src/redux";
import * as S from "src/styles/main";

const buildSidebar = (artcc: Artcc, user: UserInfo) => {
  const items: SidebarItemModel[] = [
    {
      name: "ARTCC",
      path: "",
      faIcon: faHome,
    },
  ];

  if (user.isAdmin || user.hasRole(ArtccRole.Administrator, artcc.id) || user.hasRole(ArtccRole.FacilityEngineer, artcc.id)) {
    items.push(
      {
        name: "Facilities",
        path: "facilities",
        icon: "fa-solid fa-tower-control",
      },
      {
        name: "Video Maps",
        path: "video-maps",
        faIcon: faMap,
      },
      {
        name: "Transceivers",
        path: "transceivers",
        faIcon: faTowerCell,
      },
      {
        name: "Auto ATC Rules",
        path: "auto-atc",
        faIcon: faRobot,
      },
    );

    if (artcc.hasNeighboringNonNasFacilities()) {
      items.splice(2, 0, {
        name: "Foreign/Oceanic Facilities",
        path: "foreign-facilities",
        faIcon: faGlobe,
      });
    }
  }

  items.push(
    {
      name: "Training",
      path: "training",
      faIcon: faGraduationCap,
    },
    {
      name: "Documentation",
      path: "docs",
      newTab: true,
      faIcon: faBookOpen,
    },
  );

  if (user.canManageUsers()) {
    items.splice(items.length - 1, 0, {
      name: "User Management",
      path: "user-management",
      faIcon: faUser,
    });
  }

  return items;
};

function Sidebar() {
  const artcc = useAppSelector(artccSelector);
  const user = useAppSelector(userSelector);

  const [items, setItems] = useState<SidebarItemModel[]>([]);

  useEffect(() => {
    setItems(buildSidebar(artcc, user));
  }, [artcc.id]);

  return (
    <S.Sidebar className="main-sidebar elevation-4 sidebar-dark-primary position-fixed">
      <Link to="/" className="brand-link">
        <img src="/img/logo.png" alt="CRC Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">vNAS Data Admin</span>
      </Link>
      <div className="sidebar">
        <nav className="mt-2 overflow-hidden">
          <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent">
            {items.map((item) => (
              <SidebarItem key={item.name} item={item} />
            ))}
          </ul>
        </nav>
      </div>
    </S.Sidebar>
  );
}

export default Sidebar;
