import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkLoginAsync, loginAsync, logoutAsync } from "@vatsim-vnas/js-libs/api/data";
import { getVnasConfigurationAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { UserInfo } from "@vatsim-vnas/js-libs/models/data-api";
import { VnasConfiguration } from "@vatsim-vnas/js-libs/models/vnas";
import { toast } from "react-toastify";
import { VATSIM_CLIENT_ID, processResponse } from "src/utils";
import { RootState } from "../store";

interface LoginProps {
  code: string;
  redirectUrl: string;
}

export const getVnasConfiguration = createAsyncThunk("auth/getVnasConfiguration", async () => {
  const res = await getVnasConfigurationAsync();
  return processResponse(res, "Failed to get vNAS configuration");
});

export const login = createAsyncThunk("auth/login", async (data: LoginProps) => {
  const res = await loginAsync(data.code, data.redirectUrl, VATSIM_CLIENT_ID);
  if (res.ok) {
    if (res.data!.artccSummaries.length) {
      return res.data!;
    }
    toast.error("Failed to log in: User does not have access to any ARTCCs");
    return undefined;
  }
  toast.error(`Failed to log in: ${res.statusText}`);
  return undefined;
});

export const checkLogin = createAsyncThunk("auth/checkLogin", async () => {
  const res = await checkLoginAsync();
  return processResponse(res);
});

export const logout = createAsyncThunk("auth/logout", async () => {
  const res = await logoutAsync();
  return processResponse(res, undefined, "Successfully logged out");
});

interface AuthState {
  user?: UserInfo;
  loggedIn: boolean;
  vnasConfiguration?: VnasConfiguration;
}

const initialState: AuthState = {
  loggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getVnasConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        state.vnasConfiguration = action.payload;
      }
    });
    builder.addCase(getVnasConfiguration.rejected, (_, action) => {
      toast.error(`Failed to get vNAS configuration: ${action.error.message}`);
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedIn = true;
        state.user = action.payload;
        state.user.artccSummaries.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    builder.addCase(login.rejected, (_, action) => {
      toast.error(`Failed to log in: ${action.error.message}`);
    });
    builder.addCase(checkLogin.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedIn = true;
        state.user = action.payload;
        state.user.artccSummaries.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loggedIn = false;
      state.user = undefined;
    });
    builder.addCase(logout.rejected, (state, action) => {
      if (action.payload) {
        state.loggedIn = false;
        state.user = undefined;
      }
    });
  },
  reducers: {},
});

export const vnasConfigurationSelector = (state: RootState) => state.auth.vnasConfiguration!;
export const userSelector = (state: RootState) => state.auth.user!;
export const loggedInSelector = (state: RootState) => state.auth.loggedIn;

export default authSlice.reducer;
