import { AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import { GeoPoint } from "@vatsim-vnas/js-libs/models/vnas/common";
import { FormikProps } from "formik";
import React from "react";
import { TextareaInput } from "../ui";

interface CrossingLineProps {
  formik: FormikProps<AutoAtcRule>;
  name: string;
}

const parseGeoPoint = (value: string) => {
  const parts = value.split(",").map((coord) => coord.trim());
  if (parts.length !== 2) {
    return undefined;
  }

  const [lat, lon] = parts.map(parseFloat);
  if (Number.isNaN(lat) || Number.isNaN(lon)) {
    return undefined;
  }

  return new GeoPoint(lat, lon);
};

const parseGeoPoints = (value: string[] | undefined): GeoPoint[] => {
  if (!value) {
    return [];
  }

  return value.map((v) => parseGeoPoint(v)).filter((g) => g) as GeoPoint[];
};

const displayGeoPoints = (value: GeoPoint[]) => {
  return value.map((g) => `${g.lat}, ${g.lon}`).join("\r\n");
};

function CrossingLine({ formik, name }: Readonly<CrossingLineProps>) {
  return (
    <TextareaInput
      formik={formik}
      label="Crossing Line"
      placeholder="42.1, -71.2"
      rows={3}
      name={name}
      valueProcessor={parseGeoPoints}
      displayValueProcessor={displayGeoPoints}
    />
  );
}

export default CrossingLine;
