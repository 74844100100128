import { AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Input } from "../ui";
import AltimeterStation from "./AltimeterStation";
import AltitudeConstraint from "./AltitudeConstraint";
import SpeedConstraint from "./SpeedConstraint";

interface DescentCrossingRestrictionProps {
  formik: FormikProps<AutoAtcRule>;
}

function DescentCrossingRestriction({ formik }: Readonly<DescentCrossingRestrictionProps>) {
  if (!formik.values.descentCrossingRestriction) {
    return <Card />;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Descent Crossing Restriction</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md className="mb-3">
            <Input formik={formik} label="Crossing Fix" placeholder="CCC" name="descentCrossingRestriction.crossingFix" />
          </Col>
          <Col md className="mb-3">
            <Input
              formik={formik}
              label="Fix Name"
              placeholder="20 NM east of NELIE"
              name="descentCrossingRestriction.crossingFixName"
              allowLowercase
            />
          </Col>
        </Row>
        <hr />
        <AltitudeConstraint formik={formik} baseName="descentCrossingRestriction" allowConstraintType />
        <hr />
        <SpeedConstraint formik={formik} baseName="descentCrossingRestriction" />
        <hr />
        <AltimeterStation formik={formik} baseName="descentCrossingRestriction" />
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default DescentCrossingRestriction;
