import { TrainingAircraftSet, TrainingAirport as TrainingAirportModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, CommaListInput, DeleteIconButton, Input, Table } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils/helpers";

interface AircraftSetConfigurationProps {
  formik: FormikProps<TrainingAirportModel>;
}

function AircraftSetConfiguration({ formik }: Readonly<AircraftSetConfigurationProps>) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Aircraft Sets</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add Aircraft Set" onClick={() => addToFormikArray(formik, "trainingAircraftSets", new TrainingAircraftSet())} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Airline Icao Code</th>
                    <th>Weight</th>
                    <th>Aircraft Type Codes</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.trainingAircraftSets.map((set, i) => (
                    <tr key={set.id}>
                      <td>
                        <Input tableCell formik={formik} name={`trainingAircraftSets[${i}].airlineIcaoCode`} placeholder="AAL" />
                      </td>
                      <td>
                        <Input tableCell formik={formik} name={`trainingAircraftSets[${i}].weight`} placeholder="25" number />
                      </td>
                      <td>
                        <CommaListInput
                          tableCell
                          name={`trainingAircraftSets[${i}].aircraftTypeCodes`}
                          formik={formik}
                          placeholder="B738, A21N, B788..."
                          sort
                        />
                      </td>
                      <td>
                        <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "trainingAircraftSets", set)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default AircraftSetConfiguration;
