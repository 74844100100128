import React from "react";
import { Container } from "react-bootstrap";
import { Airports, FlightplanSearchAndReplace, Scenarios, WeatherScenarios } from "src/components/training";

function Training() {
  return (
    <>
      <h1 className="content-header">Training</h1>
      <section className="content">
        <Container fluid>
          <Scenarios />
          <Airports />
          <WeatherScenarios />
          <FlightplanSearchAndReplace />
        </Container>
      </section>
    </>
  );
}

export default Training;
