import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sidebarIsCollapsedSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/main";

export interface SidebarItemModel {
  name: string;
  path: string;
  newTab?: boolean;
  faIcon?: IconProp;
  icon?: string;
}

interface SidebarItemProps {
  item: SidebarItemModel;
}

function SidebarItem({ item }: Readonly<SidebarItemProps>) {
  const sidebarIsCollapsed = useAppSelector(sidebarIsCollapsedSelector);
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (location) {
      setIsActive(item.path === location.pathname.split("/")[1]);
    }
  }, [location, item]);

  return (
    <li className={`nav-item${sidebarIsCollapsed ? " menu-open" : ""}`}>
      <S.SidebarButton className={`nav-link ${isActive ? " active" : ""}`} type="button" to={item.path} target={item.newTab ? "_blank" : ""}>
        {item.faIcon ? <FontAwesomeIcon icon={item.faIcon} className="nav-icon" /> : <i className={`${item.icon} nav-icon`} />}
        <p>{item.name}</p>
      </S.SidebarButton>
    </li>
  );
}

export default SidebarItem;
