import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

interface ConfirmOverwriteModalProps {
  show: boolean;
  onDiscard: () => void;
  onForceSave: () => void;
}

function ConfirmOverwriteModal({ show, onDiscard, onForceSave }: Readonly<ConfirmOverwriteModalProps>) {
  return (
    <Modal backdrop="static" show={show} size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Confirm Overwrite</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            You are attempting to overwrite changes made to this ARTCC by another user.
            <br />- If you discard changes, your changes will be lost and existing changes will be loaded.
            <br />- If you force save, existing changes will be overwritten by your changes.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button type="button" className="btn btn-danger" onClick={onDiscard}>
          Discard Changes
        </button>
        <button className="btn btn-danger" type="button" onClick={onForceSave}>
          Force Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmOverwriteModal;
