import { faAngleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormikProps } from "formik";
import React from "react";
import { Card, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as S from "src/styles/ui";
import IconButton from "./IconButton";

interface SaveButtonProps<F> {
  formik: FormikProps<F>;
  backText?: string;
}

function SaveButtonBar<F>({ formik, backText }: Readonly<SaveButtonProps<F>>) {
  const navigate = useNavigate();
  const saveMessage = formik.dirty ? "Unsaved changes" : "No changes to save";

  return (
    <S.SaveFooter>
      <Card>
        <Card.Body>
          <Row>
            {backText && <IconButton type="secondary" icon={faAngleLeft} text={backText} onClick={() => navigate(-1)} />}
            {formik.submitCount > 0 && !formik.isValid ? (
              <div className="ml-auto mr-3 d-flex align-items-center text-danger">Error in form</div>
            ) : (
              <div className={`ml-auto mr-3 d-flex align-items-center ${formik.dirty ? "text-warning" : ""}`}>{saveMessage}</div>
            )}
            <IconButton type="success" icon={faSave} text="Save" submit disabled={!formik.dirty} />
          </Row>
        </Card.Body>
      </Card>
    </S.SaveFooter>
  );
}

SaveButtonBar.defaultProps = {
  backText: undefined,
};

export default SaveButtonBar;
