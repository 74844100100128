import React from "react";

interface TableNoRowsProps {
  rows: unknown[];
  text: string;
}

function TableNoRows({ rows, text }: Readonly<TableNoRowsProps>) {
  if (rows.length) {
    return undefined;
  }
  return (
    <tr>
      <td colSpan={999} className="text-center">
        <i>{text}</i>
      </td>
    </tr>
  );
}

export default TableNoRows;
