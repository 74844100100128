import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

interface LoadingCardProps {
  text: string;
  title: string;
}

function LoadingCard({ text, title }: Readonly<LoadingCardProps>) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col className="d-flex justify-content-center">{text}</Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <h4>
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default LoadingCard;
