import { getTrainingAirportAsync, saveTrainingAirportAsync } from "@vatsim-vnas/js-libs/api/data";
import { TrainingAirport as TrainingAirportModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AircraftSetConfiguration,
  AirportConfiguration,
  CustomApproachConfiguration,
  HoldConfiguration,
  RadarVectorDepartureConfiguration,
} from "src/components/training";
import { LoadingCard, SaveButtonBar } from "src/components/ui";
import { artccSelector, setUnsavedData, useAppDispatch, useAppSelector } from "src/redux";
import { processResponse } from "src/utils";

function TrainingAirport() {
  const id = useParams().id!;
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: TrainingAirportModel, formik: FormikHelpers<TrainingAirportModel>) => {
    const res = await saveTrainingAirportAsync(values);
    processResponse(res, "Failed to save changes", "Successfully saved changes");
    if (res.ok) {
      formik.resetForm({ values });
    }
  };

  const formik = useFormik({
    initialValues: undefined!,
    enableReinitialize: true,
    validationSchema: TrainingAirportModel.schema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    (async () => {
      const res = await getTrainingAirportAsync(id);
      if (res.ok) {
        formik.resetForm({ values: res.data });
      } else {
        toast.error("Failed to load Airport");
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (formik.values && formik.values.artccId !== artcc.id) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">{id}</h1>
      <section className="content">
        <Container fluid>
          {formik.values ? (
            <Form onSubmit={formik.handleSubmit}>
              <AirportConfiguration formik={formik} />
              <AircraftSetConfiguration formik={formik} />
              <HoldConfiguration formik={formik} />
              <RadarVectorDepartureConfiguration formik={formik} />
              <CustomApproachConfiguration formik={formik} />
              <SaveButtonBar formik={formik} backText="Back to Airports" />
            </Form>
          ) : (
            <LoadingCard text="Loading Airport" title="General" />
          )}
        </Container>
      </section>
    </>
  );
}

export default TrainingAirport;
