import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { Container } from "react-bootstrap";
import { FallbackProps } from "react-error-boundary";
import { IconButton } from "src/components/ui";
import * as S from "src/styles/ui";

const BUG_REPORT_DISCORD_FORUM_URL = "https://discord.com/channels/953714419597201408/1022959586958909500";

function ErrorPage({ error }: Readonly<FallbackProps>) {
  return (
    <Container fluid className="vw-100 vh-100 text-center p-5">
      <S.ErrorCode data-text="Error">Error</S.ErrorCode>
      <p className="lead">We&apos;ve been navigating by the North Star, which turned out to be the Delta shuttle from LaGuardia...</p>
      <S.ErrorText className="text-danger mt-3">{error.message}</S.ErrorText>
      <IconButton
        type="discord"
        text="Report on Discord"
        icon={faDiscord}
        className="mt-3"
        onClick={() => window.open(BUG_REPORT_DISCORD_FORUM_URL)}
      />
    </Container>
  );
}

export default ErrorPage;
