import React, { ReactElement, useEffect, useRef, useState } from "react";
import * as S from "src/styles/main";
import DropdownMenu from "./DropdownMenu";

interface DropdownProps {
  buttonTemplate: ReactElement;
  className?: string;
  menuTemplate: ReactElement;
  onLeft?: boolean;
}

function Dropdown({ buttonTemplate, className, menuTemplate, onLeft = false }: Readonly<DropdownProps>) {
  const dropdownRef = useRef<HTMLDivElement>(undefined!);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (!dropdownRef?.current?.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`nav-item dropdown ${className}`}>
      <S.NavItem onClick={() => setDropdownOpen(!dropdownOpen)} type="button" className="nav-link">
        {buttonTemplate}
      </S.NavItem>
      {dropdownOpen && <DropdownMenu onLeft={onLeft}>{menuTemplate}</DropdownMenu>}
    </div>
  );
}

Dropdown.defaultProps = {
  className: undefined,
  onLeft: false,
};

export default Dropdown;
