import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadVideoMapAsync } from "@vatsim-vnas/js-libs/api/data";
import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { removeFileExtension } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BaseSwitch, CommaListInput, UploadFileButton } from "src/components/ui";
import { addVideoMap, artccSelector, saveArtcc, saveVideoMap, useAppDispatch, useAppSelector, videoMapsSelector } from "src/redux";

interface BatchUploadGeoJsonModalProps {
  show: boolean;
  onClose: () => void;
}

function BatchUploadGeoJsonModal({ show, onClose }: Readonly<BatchUploadGeoJsonModalProps>) {
  const videoMaps = useAppSelector(videoMapsSelector);
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();

  const [createNew, setCreateNew] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);
  const [uploadLog, setUploadLog] = useState("");
  const [files, setFiles] = useState<FileList>();

  useEffect(() => {
    setCreateNew(false);
    setUploading(false);
    setDoneUploading(false);
    setUploadLog("");
    setFiles(undefined);
  }, [show]);

  const logError = (message: string) => {
    setUploadLog((log) => {
      return `${log}❌ ${message}\n`;
    });
  };

  const logSuccess = (message: string) => {
    setUploadLog((log) => {
      return `${log}✅ ${message}\n`;
    });
  };

  const processFiles = async () => {
    setUploading(true);
    if (files) {
      let changed = false;
      Promise.all(
        Array.from(files).map(async (f) => {
          if (f.size === 0) {
            logError(`Unable to upload ${f.name}: file cannot be empty.`);
          } else {
            const matchingVideoMaps = videoMaps.filter((v) => v.sourceFileName === f.name || v.id === removeFileExtension(f.name));
            if (matchingVideoMaps.length > 1) {
              logError(`Unable to upload ${f.name}: multiple video maps have matching file name: ${matchingVideoMaps.map((v) => v.name).join(", ")}`);
            } else if (!matchingVideoMaps.length) {
              if (createNew) {
                const newVideoMap = new VideoMap();
                newVideoMap.name = f.name.replace(/\.[^/.]+$/, "");
                newVideoMap.sourceFileName = f.name;
                newVideoMap.tags = tags.filter((v, i, a) => a.indexOf(v) === i);
                const res = await uploadVideoMapAsync(artcc.id, newVideoMap.id, f);
                if (res.ok) {
                  changed = true;
                  dispatch(addVideoMap(newVideoMap));
                  logSuccess(`Successfully created ${newVideoMap.name} video map with ${f.name}`);
                } else {
                  logError(`Unable to upload ${f.name}: ${res.statusText}`);
                }
              } else {
                logError(`Unable to upload ${f.name}: no video maps with file name`);
              }
            } else {
              const res = await uploadVideoMapAsync(artcc.id, matchingVideoMaps[0].id, f);
              if (res.ok) {
                const newVideoMap = matchingVideoMaps[0];
                newVideoMap.lastUpdatedAt = new Date();
                newVideoMap.tags = newVideoMap.tags.concat(tags).filter((v, i, a) => a.indexOf(v) === i);
                logSuccess(`Successfully updated ${matchingVideoMaps[0].sourceFileName} for ${matchingVideoMaps[0].name} video map`);
                changed = true;
                dispatch(saveVideoMap(newVideoMap));
              } else {
                logError(`Unable to upload ${f.name}: ${res.statusText}`);
              }
            }
          }
        }),
      ).then(() => {
        if (changed) {
          dispatch(saveArtcc("Successfully saved changes"));
        }
        setDoneUploading(true);
      });
    } else {
      logError(`No files selected`);
      setDoneUploading(true);
    }
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Batch Upload GeoJSON</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <UploadFileButton
              multiple
              disabled={uploading}
              accept=".geojson"
              label="Select GeoJSON Files"
              onSelect={(f) => {
                if (f) {
                  setFiles(f as FileList);
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CommaListInput label="Tags" placeholder="STARS, EOVM, ..." sort={false} allowSpaces onChange={(v) => setTags(v)} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <BaseSwitch
              checked={createNew}
              disabled={uploading}
              label="Create new video maps from unrecognized GeoJSON files"
              onChange={(e) => setCreateNew(e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h6>Output Log:</h6>
            <textarea className="form-control" rows={5} disabled value={uploadLog} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
          Close
        </button>
        {!doneUploading && (
          <button className="btn btn-success" type="button" disabled={uploading} onClick={() => processFiles()} style={{ width: "75px" }}>
            {uploading ? <FontAwesomeIcon icon={faGear} className="fa-spin" /> : "Upload"}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default BatchUploadGeoJsonModal;
