import { getTrainingWeatherAsync, saveTrainingWeatherAsync } from "@vatsim-vnas/js-libs/api/data";
import { TrainingWeather as TrainingWeatherModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { WeatherConfiguration, WindLayerConfiguration } from "src/components/training";
import { LoadingCard, SaveButtonBar } from "src/components/ui";
import { artccSelector, setUnsavedData, useAppDispatch, useAppSelector } from "src/redux";

function TrainingWeather() {
  const id = useParams().id!;
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: TrainingWeatherModel, formik: FormikHelpers<TrainingWeatherModel>) => {
    const res = await saveTrainingWeatherAsync(values);
    if (res.ok) {
      toast.success("Successfully saved changes");
      formik.resetForm({ values });
    } else {
      toast.error(`Failed to save changes: ${res.statusText}`);
    }
  };

  const formik = useFormik({
    initialValues: undefined!,
    enableReinitialize: true,
    validationSchema: TrainingWeatherModel.schema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getTrainingWeatherAsync(id).then((a) => {
      if (a.ok) {
        formik.resetForm({ values: a.data });
      } else {
        toast.error("Failed to load Weather Scenario");
      }
    });
  }, []);

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (formik.values && formik.values.artccId !== artcc.id) {
    return <Navigate to="/404" replace />;
  }
  return (
    <>
      <h1 className="content-header">{formik.values?.name ?? "Scenario"}</h1>
      {formik.values ? (
        <section className="content">
          <Container fluid>
            <Form onSubmit={formik.handleSubmit}>
              <WeatherConfiguration formik={formik} />
              <WindLayerConfiguration formik={formik} />
              <SaveButtonBar formik={formik} backText="Back to Training" />
            </Form>
          </Container>
        </section>
      ) : (
        <LoadingCard text="Loading Weather" title="General" />
      )}
    </>
  );
}

export default TrainingWeather;
