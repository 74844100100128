import React from "react";
import * as S from "src/styles/ui";

interface DropdownMenuProps {
  children: React.ReactNode;
  onLeft: boolean;
}

function DropdownMenu({ children, onLeft }: Readonly<DropdownMenuProps>) {
  return (
    <S.DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-md show" $onLeft={onLeft}>
      {children}
    </S.DropdownMenu>
  );
}

export default DropdownMenu;
