import { Scenario, ScenarioTrigger } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, Input, OrderButtonPair, Table, TableNoRows } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface InitializationTriggerConfigurationProps {
  formik: FormikProps<Scenario>;
}

function InitializationTriggerConfiguration({ formik }: Readonly<InitializationTriggerConfigurationProps>) {
  const triggers = formik.values.initializationTriggers;

  return (
    <Card>
      <Card.Header>
        <Card.Title>Initialization Triggers</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add Trigger" onClick={() => addToFormikArray(formik, "initializationTriggers", new ScenarioTrigger())} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Command</th>
                    <th>Time Offset (s)</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {triggers.map((trigger, i) => (
                    <tr key={trigger.id}>
                      <td>
                        <Input tableCell formik={formik} name={`initializationTriggers[${i}].command`} placeholder="ADD V S P -270 10 2500" />
                      </td>
                      <td>
                        <Input tableCell formik={formik} name={`initializationTriggers[${i}].timeOffset`} placeholder="0" number />
                      </td>
                      <td>
                        <ButtonGroup>
                          <OrderButtonPair formik={formik} name="initializationTriggers" index={i} />
                          <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "initializationTriggers", trigger)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                  <TableNoRows rows={triggers} text="No Initialization Triggers defined" />
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default InitializationTriggerConfiguration;
