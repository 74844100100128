import { Facility, TdlsConfiguration as TdlsConfigurationModel } from "@vatsim-vnas/js-libs/models/facilities";
import { ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";

import { FormikProps } from "formik";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { EditIconButton, SelectInput, Switch, Table } from "src/components/ui";
import { TdlsModal } from "../ui/modal";

interface TdlsConfigurationProps {
  formik: FormikProps<Facility>;
}

function TdlsConfiguration({ formik }: Readonly<TdlsConfigurationProps>) {
  const [selectedField, setSelectedField] = useState<ClearanceField>();
  const { tdlsConfiguration } = formik.values;

  return (
    <Card>
      <Card.Header>
        <Card.Title>TDLS Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Switch label="Enable TDLS" name="tdlsConfiguration" checkedValue={() => new TdlsConfigurationModel()} formik={formik} />
          </Col>
        </Row>
        {tdlsConfiguration && (
          <>
            <Row className="mt-3">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Setting</th>
                      <th>Mandatory</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>SID</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatorySid" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.Sid)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Climb Out</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatoryClimbout" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.Climbout)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Climb Via</td>
                      <td>
                        <Switch
                          formik={formik}
                          name="tdlsConfiguration.mandatoryClimbvia"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue("tdlsConfiguration.mandatoryInitialAlt", false);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.Climbvia)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Initial Altitude</td>
                      <td>
                        <Switch
                          formik={formik}
                          name="tdlsConfiguration.mandatoryInitialAlt"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue("tdlsConfiguration.mandatoryClimbvia", false);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.InitialAlt)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Departure Frequency</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatoryDepFreq" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.DepFreq)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Expect</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatoryExpect" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.Expect)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Info</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatoryContactInfo" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.ContactInfo)} />
                      </td>
                    </tr>
                    <tr>
                      <td>Local Info</td>
                      <td>
                        <Switch formik={formik} name="tdlsConfiguration.mandatoryLocalInfo" />
                      </td>
                      <td>
                        <EditIconButton onClick={() => setSelectedField(ClearanceField.LocalInfo)} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={3}>
                <SelectInput
                  formik={formik}
                  name="tdlsConfiguration.defaultSidId"
                  label="Default SID"
                  clearOption="NO DEFAULT"
                  options={tdlsConfiguration.sids.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                />
              </Col>
              {tdlsConfiguration.defaultSidId && (
                <Col lg={3}>
                  <SelectInput
                    formik={formik}
                    name="tdlsConfiguration.defaultTransitionId"
                    label="Default Transition"
                    options={tdlsConfiguration.getSid(tdlsConfiguration.defaultSidId).transitions.map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </Card.Body>
      <Card.Footer />
      <TdlsModal field={selectedField} formik={formik} onClose={() => setSelectedField(undefined)} />
    </Card>
  );
}

export default TdlsConfiguration;
