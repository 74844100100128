import { FormikProps } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import * as S from "src/styles/ui";
import { hasTouchedError } from "src/utils";

interface ErrorTableCellProps<F> {
  displayValue: string;
  errorMessage: string;
  formik: FormikProps<F>;
  name: string;
}

function ErrorTableCell<F>({ displayValue, errorMessage, formik, name }: Readonly<ErrorTableCellProps<F>>) {
  const isInvalid = hasTouchedError(formik, name);
  return (
    <Form.Group>
      <S.BlankFormControl disabled isInvalid={isInvalid} value={displayValue} />
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default ErrorTableCell;
