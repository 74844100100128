import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadNonNasFacilitiesAsync, saveNonNasFacilityAsync } from "@vatsim-vnas/js-libs/api/data";
import { NonNasFacility } from "@vatsim-vnas/js-libs/models/facilities";
import { toast } from "react-toastify";
import { processResponse } from "src/utils";
import { RootState } from "../store";

export const loadNonNasFacilities = createAsyncThunk("nonNas/loadNonNasFacilities", async () => {
  const res = await loadNonNasFacilitiesAsync();
  return processResponse(res, "Failed to load non-NAS facilities");
});

export const saveNonNasFacility = createAsyncThunk("nonNas/saveNonNasFacility", async (facility: NonNasFacility) => {
  const res = await saveNonNasFacilityAsync(facility);
  return processResponse(res, "Failed to save changes", "Successfully saved changes", facility);
});

interface NonNasState {
  facilities: NonNasFacility[];
}

const initialState: NonNasState = {
  facilities: [],
};

export const nonNasSlice = createSlice({
  name: "nonNas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadNonNasFacilities.fulfilled, (state, action) => {
      if (action.payload) {
        state.facilities = action.payload;
        state.facilities.sort((a, b) => a.id.localeCompare(b.id));
      }
    });
    builder.addCase(loadNonNasFacilities.rejected, (_, action) => {
      toast.error(`Failed to load non-NAS facilities: ${action.error.message}`);
    });
    builder.addCase(saveNonNasFacility.fulfilled, (state, action) => {
      if (action.payload) {
        state.facilities.find((f) => f.id === action.payload!.id)!.populate(action.payload);
      }
    });
    builder.addCase(saveNonNasFacility.rejected, (_, action) => {
      toast.error(`Failed to save changes: ${action.error.message}`);
    });
  },
});

export default nonNasSlice.reducer;

export const nonNasFacilitiesSelector = (state: RootState) => state.nonNas.facilities;
