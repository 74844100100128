import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ArtccDropdown, UserDropdown } from "src/components/main";
import { toggleSidebar, useAppDispatch } from "src/redux";
import * as S from "src/styles/main";

function Header() {
  const dispatch = useAppDispatch();

  return (
    <nav className="main-header navbar navbar-expand navbar-dark">
      <ul className="navbar-nav">
        <li className="nav-item">
          <S.NavItem onClick={() => dispatch(toggleSidebar())} type="button" className="nav-link">
            <FontAwesomeIcon icon={faBars} />
          </S.NavItem>
        </li>
      </ul>
      <span className="d-flex navbar-nav align-items-center">
        <span className="mr-n2 d-none d-md-block">Currently editing</span>
        <ArtccDropdown />
      </span>
      <span className="ml-auto navbar-nav align-items-center d-flex">
        <span className="mr-n2 d-none d-md-block">Welcome,</span>
        <UserDropdown />
      </span>
    </nav>
  );
}

export default Header;
