import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { artccRoleToString } from "@vatsim-vnas/js-libs/models/data-api";
import React, { useMemo } from "react";
import { Dropdown } from "src/components/ui";
import { logout, useAppDispatch, useAppSelector, userSelector } from "src/redux";
import * as S from "src/styles/main";

function UserDropdown() {
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const artccRoles = useMemo(() => [...user.artccRoles].sort((a, b) => a.artccId.localeCompare(b.artccId)), []);

  return (
    <Dropdown
      className="user-menu"
      onLeft
      buttonTemplate={
        <>
          <FontAwesomeIcon icon={faUser} className="d-md-none" />
          <b className="d-none d-md-block">
            {user.firstName} {user.lastName}
          </b>
        </>
      }
      menuTemplate={
        <>
          <S.UserHeader className="bg-primary">
            {user.cid}
            {user.isAdmin && (
              <div>
                <small>Administrator</small>
              </div>
            )}
            {artccRoles.map((r) => (
              <div key={r.artccId + r.role}>
                <small>
                  {r.artccId} {artccRoleToString(r.role)}
                </small>
              </div>
            ))}
          </S.UserHeader>
          <li className="user-footer d-flex justify-content-center">
            <button type="button" className="btn btn-default btn-flat" onClick={() => dispatch(logout())}>
              Log Out
            </button>
          </li>
        </>
      }
    />
  );
}

export default UserDropdown;
