import styled from "styled-components";

export const LoginBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/img/login-background.png);
`;

export const LoginSidebar = styled.div`
  height: 100vh;
  width: 35vw;
  min-width: 300px;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #454d55;
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
  color: white;
`;

export const LoginPanel = styled.div`
  background-color: #6c757d;
  padding: 60px 0;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: -5px 0 40px 5px rgba(0, 0, 0, 0.5);
`;

export const Footer = styled.footer`
  background-color: #343a40;
  color: #869099;
  right: 0;
  width: 100%;
  text-align: right;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #4b545c;
`;

export const Title = styled.span`
  font-size: 2em;
  margin: 25px 0;
`;
