import { TrainingAirport } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input } from "src/components/ui";

interface TrainingAirportHoldModalProps {
  show: boolean;
  index: number | undefined;
  formik: FormikProps<TrainingAirport>;
  onClose: () => void;
}

function TrainingAirportHoldModal({ show, index, formik, onClose }: Readonly<TrainingAirportHoldModalProps>) {
  if (index === undefined) {
    return undefined;
  }

  const hold = formik.values.holds[index];

  if (!hold) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Hold</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <Input formik={formik} name={`holds[${index}].fix`} label="Fix or FRD" placeholder="BOS or BOS060010" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input number formik={formik} name={`holds[${index}].course`} label="Inbound Course" placeholder="090" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex">
            <div className="flex-grow-1">
              <Input number formik={formik} name={`holds[${index}].legLength`} label="Leg Length" placeholder="10" />
            </div>
            <div className="d-flex ml-2" style={{ transform: "translateY(15px)" }}>
              <Form.Check
                inline
                label="nm"
                type="radio"
                name="legLengthUnits"
                defaultChecked={!hold.legLengthIsMinutes}
                onChange={(e) => formik.setFieldValue(`holds[${index}].legLengthIsMinutes`, !e.target.checked)}
              />
              <Form.Check
                inline
                label="min"
                type="radio"
                name="legLengthUnits"
                defaultChecked={hold.legLengthIsMinutes}
                onChange={(e) => formik.setFieldValue(`holds[${index}].legLengthIsMinutes`, e.target.checked)}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Label>Turn Direction</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              inline
              label="Left Turns"
              type="radio"
              name="turnDirection"
              defaultChecked={!hold.rightTurns}
              onChange={(e) => formik.setFieldValue(`holds[${index}].rightTurns`, !e.target.checked)}
            />
            <Form.Check
              inline
              label="Right Turns"
              type="radio"
              name="turnDirection"
              defaultChecked={hold.rightTurns}
              onChange={(e) => formik.setFieldValue(`holds[${index}].rightTurns`, e.target.checked)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingAirportHoldModal;
